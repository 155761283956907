/*
	plan:
		get the reservable times for a particular
		day of the week?

	import { filterReservableTimes } from "@/utilities/amenities/filterReservableTimes"

	const { reservableTimes } = filterReservableTimes ({
		amenityReservableTimes: [{
			startTime: "",
			endTime: ""
		},{
			startTime: "",
			endTime: ""
		}]
	})

	
	// returns
	// const reservableTimes = {
	// 	"Sunday": [],
	// 	"Monday": [],
	// 	"Tuesday": [],
	// 	"Wednesday": [],
	// 	"Thursday": [],
	// 	"Friday": [],
	// 	"Saturday": [],
	// };
*/

import _get from 'lodash/get'

export function filterReservableTimes({ amenityReservableTimes }) {
  const list = amenityReservableTimes

  const wkDays = {
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: []
  }

  for (let a = 0; a < list.length; a++) {
    const entry = list[a]

    const startTime = _get(entry, 'startTime', '')
    const endTime = _get(entry, 'endTime', '')
    const wkDay = _get(entry, 'dayOfWeek', '')

    wkDays[wkDay].push({
      startTime,
      endTime
    })
  }

  return { reservableTimes: wkDays }
}
