/*
	Get days in month
		for example: 
			all saturdays in the november 5932?
*/

/*
	import { allDatesInMonth } from '@/utilities/Date/allDatesInMonth'

  allDatesInMonth ({
    year: 2022,
    month: 1
  })  
*/

import { addSuffix } from '@/utilities/Date/addSuffix'

import moment from 'moment'

/*
	year: 2022 (UTC)
	month: 1 to 12
	dayOfWeek: 1 to 7
*/
export function allDatesInMonth({ year, month, dayOfWeek = null, suffix = false }) {
  const totalDays = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth()

  month = month - 1

  if (typeof dayOfWeek === 'number') {
    dayOfWeek = dayOfWeek - 1
  }

  const dates = []
  for (let a = 1; a <= totalDays; a++) {
    const date = new Date(year, month, a)
    const day = date.getDay()

    if (typeof dayOfWeek === 'number') {
      if (day === dayOfWeek) {
        dates.push(date)
      }
    } else {
      dates.push(date)
    }
  }

  if (suffix) {
    return {
      dates: dates.map(date => {
        const number = moment(date).date()

        return addSuffix(number)
      })
    }
  }

  return {
    dates
  }
}
