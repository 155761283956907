/*
	import { getAmenity } from '@/services/Amenities/Amenity/Get';

  const { successful, message, data } = await getAmenity ({
    amenityID
  });
  if (!successful) {
    console.error (message);
    return;
  }
*/

import kms from '@/services/kms'

export async function getAmenity({ amenityID }) {
  let message = 'The amenity could not be retrieved.'

  try {
    const data = await kms.get(`/Amenities/Amenity/${amenityID}`)

    return {
      successful: true,
      message: '',
      data
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    data: null
  }
}
