/*
	import { timeIsAvailable } from "@/utilities/time/isAvailable"

	const { 
		available // boolean 
	} = timeIsAvailable ({
		check: [ from, to ],
		availabilities: [
			[ from, to ],
			[ from, to ],
			[ from, to ],
			[ from, to ]
		],
		reservations: [
			[ from, to ]
		]
	})
*/
import { getTimeSpans } from '@/utilities/time/getSpans'
import { compareTimes } from '@/utilities/time/compare'

export function timeIsAvailable({ check, availabilities, reservations }) {
  const [c1, c2] = check

  const { openings } = getTimeSpans({
    availabilities,
    reservations
  })

  for (let f = 0; f < openings.length; f++) {
    const [o1, o2] = openings[f]

    /*
			avail if:
				o: [       ]
				c:	  [  ]
		*/
    // c1 >= o1
    // c2 <= o2
    if ([0, 1].includes(compareTimes(c1, o1)) && [0, 2].includes(compareTimes(c2, o2))) {
      return {
        available: true
      }
    }
  }

  return {
    available: false
  }
}
