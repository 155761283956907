/*
  import { outstandingPayment } from '@/services/Payments/HomeOwner/OutstandingPayment/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import kms from '@/services/kms'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await outstandingPayment.dispatch ('getOverallBalance', {
      params
    });
  */

  async getOneTimeFortisJwtToken({}, { hoaID }) {
    try {
      //Get the client's ip address first
      let path = `/FortisEpicGateway/EpicGateway/GetJwtTokenForClient?hoaID=${hoaID}`
      let { result } = await kms.get(path)
      if (result) {
        if (isDebug == true) console.debug('getOneTimeFortisJwtToken jwt=' + JSON.stringify(result))

        return {
          result: result.jwt
        }
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving a security token.`)
      console.error(exception)
    }

    return {
      result: null
    }
  },

  async registerOneTimeToken({}, { params, jwt }) {
    try {
      if (isDebug === true)
        console.debug(
          'in registerOneTimeToken... params=' + JSON.stringify(params) + ', jwt=' + jwt
        )

      //Get the the base url from our server
      const baseUrl = await kms.get('/FortisEpicGateway/EpicGateway/GetFortisBaseUrlForClient')
      if (isDebug === true) console.debug('baseUrl=' + baseUrl)

      if (baseUrl) {
        const path = `${baseUrl}registeronetimetoken`

        if (isDebug === true) console.debug('path=' + path)

        //Custom jwt header to fortis. This does not touch our server
        const { result, status } = await kms.postthirdpartyjwt(
          path,
          {
            method: params.method,
            account_number: params.account_number,
            routing_number: params.method === 'echeck' ? params.routing_number : null,
            bank_account_type: params.method === 'echeck' ? params.bank_account_type : null
          },
          jwt
        )

        if (isDebug == true) console.debug('registerOneTimeToken jwt=' + JSON.stringify(result))

        if (result) {
          return {
            result: result.token
          }
        } else {
          return {
            result: {
              status: `${status.reason_text ||
                'There was a problem registering the one-time token.'}`
            }
          }
        }
      }
    } catch (exception) {
      notifyProblem(`There was a problem registering the one-time token.`)
      console.error(exception)
    }

    return {
      result: null
    }
  },

  async getPaymentMethodList({}, { params }) {
    try {
      let { results: paymentMethods } = await kms.get('/Roster/OwnerPaymentMethod/List', { params })

      if (isDebug == true) console.debug('getPaymentMethodList=' + JSON.stringify(paymentMethods))

      const dropDownList = paymentMethods
        .map(a => ({
          value: a.ownerPaymentMethodID != null ? a.ownerPaymentMethodID : 0,
          label:
            a.paymentMethodType != null && a.paymentMethodType.length > 0
              ? `${a.paymentMethodType} - ${a.lastFourDigits} ${
                  a.accountAlias ? ' (' + a.accountAlias + ')' : ''
                }`
              : ''
        }))
        .filter(f => f.label.length > 0 && f.value > 0)

      dropDownList.unshift({ value: '', label: '<-Select Payment Method->' })

      if (isDebug == true) console.debug('...dropDownList=' + JSON.stringify(dropDownList))

      return {
        list: dropDownList
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the payment methods.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  //Bank Balance Summaries
  async getOverallBalance({}, { params }) {
    try {
      console.debug('in getOverallBalance...' + params)
      const result = await kms.get(`/AccountsReceivable/LedgerQueries/GetOverallBalance`, {
        params
      })

      if (isDebug == true) console.debug('getOverallBalance=' + JSON.stringify(result))

      return {
        result: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the current balance.`)
      console.error(exception)
    }

    return {
      survey: {}
    }
  },

  //Service Fees
  async getServiceFees({}, { params }) {
    try {
      console.debug('in getServiceFees...' + params)
      const result = await kms.get(`/Roster/OwnerElectronicPayment/GetServiceFees`, {
        params
      })

      if (isDebug == true) console.debug('getServiceFees=' + JSON.stringify(result))

      return {
        result: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the service fees.`)
      console.error(exception)
    }

    return {
      survey: {}
    }
  },

  async addSendPayment({ dispatch }, { payload = {}, done }) {
    if (isDebug == true) console.debug('addSendPayment...' + JSON.stringify(payload.ownerID))
    try {
      let path = `?ownerID=${payload.ownerID}&ownerPaymentMethodID=${payload.ownerPaymentMethodID}&paymentAmount=${payload.paymentAmount}`

      if (payload.isApprovalPending !== undefined && payload.isApprovalPending === true) {
        let path = path + '&isApprovalPending=true'
      }

      const results = await kms.post(`/Roster/Owner/SendBillingPaymentWithWallet${path}`)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (
        (results &&
          results.status &&
          results.status != undefined &&
          results.status.reasonCode &&
          results.status.reasonCode != undefined &&
          results.status.reasonCode == '000') ||
        results.status.reasonCode == '001'
      ) {
        notifyMessage(`Successfully sent your outstanding payment.`)
        done({ invalidMessage: '' })
      } else {
        notifyError('There was a problem sending your outstanding payment.')
        done({ invalidMessage: '' })
      }
    } catch (exception) {
      if (exception && exception.message) {
        done({ invalidMessage: exception.message })
      }
      console.debug(`There was a problem sending your outstanding payment.`)
      console.error(exception)
    }
  },

  async addOneTimeCreditCardPayment({ dispatch }, { payload = {}, done }) {
    if (isDebug == true) console.debug('addOneTimeCreditCardPayment...' + JSON.stringify(payload))

    try {
      const results = await kms.post(`/Roster/Owner/MakeOneTimePaymentCreditCard`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (
        results &&
        results.status &&
        results.status != undefined &&
        results.status.reasonCode &&
        results.status.reasonCode != undefined &&
        results.status.reasonCode == '000'
      ) {
        notifyMessage('Successfully sent your one-time credit card payment.')
        done({ invalidMessage: '' })
      } else {
        notifyError('There was a problem sending your one-time credit card payment.')
        done({ invalidMessage: '', details: results })
      }
    } catch (exception) {
      if (exception && exception.message) {
        done({ invalidMessage: exception.message })
      }
      console.debug(
        `There was a problem sending your one-time credit card payment.` + exception.message
      )
      console.error(exception)
    }
  },

  async addOneTimeBankAccountPayment({ dispatch }, { payload = {}, done }) {
    if (isDebug == true) console.debug('addOneTimeBankAccountPayment...' + JSON.stringify(payload))
    try {
      const results = await kms.post(`/Roster/Owner/MakeOneTimePaymentBankAccount`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (
        results &&
        results.status &&
        results.status != undefined &&
        results.status.reasonCode &&
        results.status.reasonCode != undefined &&
        results.status.reasonCode == '001'
      ) {
        notifyMessage('Successfully sent your one-time e-check payment.')
        done({ invalidMessage: '' })
      } else {
        notifyError('There was a problem sending your one-time e-check payment.')
        done({ invalidMessage: '' })
      }
    } catch (exception) {
      if (exception && exception.message) {
        done({ invalidMessage: exception.message })
      }
      console.debug(`There was a problem sending your one-time e-check payment.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const outstandingPayment = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
