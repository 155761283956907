/*
	import { deleteAmenityReservableTime } from '@/services/Amenities/AmenityReservableTime/Delete';

  const { successful, message } = await deleteAmenityReservableTime ({
    amenityReservableTimeID
  });
  if (!successful) {
    console.error (message);
    return;
  }
*/

import kms from '@/services/kms'

export async function deleteAmenityReservableTime({ amenityReservableTimeID }) {
  let message = 'The amenity reservable time could not be deleted.'

  try {
    await kms.delete(`/Amenities/AmenityReservableTime/${amenityReservableTimeID}?forceDelete=true`)

    return {
      successful: true,
      message
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message
  }
}
