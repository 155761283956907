import _get from 'lodash/get'

export function data() {
  const authUserType = _get(this, ['$store', 'getters', 'user/authUserType'], null)
  const theme = _get(this, ['$store', 'state', 'theme'], {})

  return {
    theme,

    // this is set via the
    // this.$refs.reservationChooser.open ()
    amenityData: null,
    ownerID: null,
    amenityPaymentMethodTypes: [],
    amenityFees: [],
    amenityFeeTotal: 0,
    convenienceFee: 0,
    amenityPaymentMethod: null,
    amenityPaymentMethodType: null,
    ownerPaymentMethodID: null,
    newAmenityReservation: null,
    paymentMethods: [],
    authUserType,
    isOpen: false,
    loading: true,
    reservableTimes: [],
    spans: [],
    openings: [],
    reservations: [],
    creditCardNumber: '',
    cardHolderName: '',
    cvc: '',
    zipCode: '',
    addCreditCardPaymentMethod: false,
    accountAliasCreditCard: '',
    requestJwtToken: '',
    mDate: {
      year: null,
      month: null,
      dayOfMonth: null
    },

    /*
			1 -> main
			2 -> confirm delete panel
		*/
    showing: 1,
    toDelete: {},
    toUpdate: {
      proposal: {
        startTime: null,
        endTime: null
      }
    },
    reservationToCancel: null,
    allowUpdate: 0,
    affectedReservations: [],
    affectedReservationsDetails: [],

    masks: {
      creditCard: { creditCard: true }
    }
  }
}
