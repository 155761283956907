import { amenityStore } from '@/services/Amenities/store'

export function mounted() {
  this.refresh()

  this.unsubAmenityStore = amenityStore.subscribe(mutation => {
    const mType = mutation.type
    if (
      [
        'amenityReservableTimeAdded',
        'amenityReservableTimeRemoved',
        'amenityReservationAdded',
        'amenityReservationCancelled'
      ].includes(mType)
    ) {
      this.refresh()
    }
  })
}
