/*
	import { getAmenityReservation } from '@/services/Amenities/AmenityReservation/Get';

  const { successful, message, reservation } = await getAmenityReservation ({
    amenityReservationID: 56
  });
  if (!successful) {
    console.error (message);
    return;
  }
*/

import kms from '@/services/kms'

export async function getAmenityReservation({ amenityReservationID }) {
  let message = 'The amenity reservation details could not be retrieved.'

  try {
    const result = await kms.get(`/Amenities/AmenityReservation/${amenityReservationID}`)

    return {
      reservation: result,
      successful: true,
      message: ''
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    reservation: null,
    successful: false,
    message
  }
}
