var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContent',{style:({ paddingBottom: '300px', position: 'relative' }),attrs:{"id":"amenities-details","amenities-details-page":""}},[_c('div',{style:({ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' })},[_c('h3',[_vm._v(_vm._s(_vm.name))]),_c('div',{style:({ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' })},[(
          typeof _vm.reservationDurationLimitInMinutes === 'number' &&
            _vm.reservationDurationLimitInMinutes > 0
        )?_c('b-taglist',{attrs:{"attached":""}},[_c('b-tag',{attrs:{"type":"is-light"}},[_vm._v("Reservation Time Limit")]),_c('b-tag',{style:({ borderLeft: '1px solid #DDD' }),attrs:{"type":"is-light"}},[_vm._v(_vm._s(Math.round(_vm.reservationDurationLimitInMinutes / 60) + ' hour(s)'))])],1):_vm._e(),(_vm.requiresApproval)?_c('b-taglist',{style:({ marginLeft: '12px' }),attrs:{"attached":""}},[_c('b-tag',{attrs:{"type":"is-light"}},[_vm._v("Reservations Require Approval")])],1):_vm._e()],1)]),_c('div',{style:({
      display: !_vm.found && !_vm.loading ? 'block' : 'none',
      padding: '100px',
      textAlign: 'center'
    })},[_vm._v(" The amenity could not be found. It may have been deleted. ")]),_c('div',{style:({ opacity: _vm.found ? 1 : 0, transition: 'opacity 1.4s' })},[_c('div',{style:(Object.assign({}, {display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '30px'},
        (_vm.breakpoint === 'desktop'
          ? {
              alignItems: 'end'
            }
          : {
              alignItems: 'unset',
              flexDirection: 'column'
            })))},[(_vm.portal === 2)?_c('b-button',{attrs:{"add-reservable-time-button":"","type":"is-primary","rounded":""},on:{"click":_vm.openAddModal}},[_vm._v("Add Reservable Time")]):_vm._e(),_c('div',{style:(Object.assign({}, {display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'},
          (_vm.breakpoint === 'desktop'
            ? {}
            : {
                margin: '20px 0 0'
              })))},[(_vm.portal === 1)?_c('b-field',{attrs:{"label":"Show"}},[_c('b-select',{model:{value:(_vm.showing),callback:function ($$v) {_vm.showing=$$v},expression:"showing"}},_vm._l((_vm.homeOwnerDisplays),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0)],1):_vm._e(),(_vm.portal === 2)?_c('b-field',{attrs:{"label":"Show"}},[_c('b-select',{model:{value:(_vm.showing),callback:function ($$v) {_vm.showing=$$v},expression:"showing"}},_vm._l((_vm.managementDisplays),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0)],1):_vm._e()],1)],1),_c('PleasantCalendar',{ref:"calendar",attrs:{"pageChange":_vm.calendarUpdate,"dayTapped":_vm.dayTapped,"events":_vm.events,"breakpoint":_vm.breakpoint}})],1),_c('ReservabilityAdder',{ref:"reservabilityAdder",attrs:{"refreshParent":_vm.refreshParent}}),_c('ReservationChooser',{ref:"reservationChooser",attrs:{"refreshParent":_vm.refreshParent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }