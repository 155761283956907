<template>
  <PageContent
    :style="{ paddingBottom: '300px', position: 'relative' }"
    id="amenities-details"
    amenities-details-page
  >
    <div :style="{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }">
      <h3>{{ name }}</h3>
      <div :style="{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }">
        <b-taglist
          attached
          v-if="
            typeof reservationDurationLimitInMinutes === 'number' &&
              reservationDurationLimitInMinutes > 0
          "
        >
          <b-tag type="is-light">Reservation Time Limit</b-tag>
          <b-tag type="is-light" :style="{ borderLeft: '1px solid #DDD' }">{{
            Math.round(reservationDurationLimitInMinutes / 60) + ' hour(s)'
          }}</b-tag>
        </b-taglist>

        <b-taglist attached v-if="requiresApproval" :style="{ marginLeft: '12px' }">
          <b-tag type="is-light">Reservations Require Approval</b-tag>
        </b-taglist>
      </div>
    </div>

    <div
      :style="{
        display: !found && !loading ? 'block' : 'none',
        padding: '100px',
        textAlign: 'center'
      }"
    >
      The amenity could not be found. It may have been deleted.
    </div>

    <div :style="{ opacity: found ? 1 : 0, transition: 'opacity 1.4s' }">
      <div
        :style="{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '30px',
          ...(breakpoint === 'desktop'
            ? {
                alignItems: 'end'
              }
            : {
                alignItems: 'unset',
                flexDirection: 'column'
              })
        }"
      >
        <b-button
          add-reservable-time-button
          v-if="portal === 2"
          type="is-primary"
          @click="openAddModal"
          rounded
          >Add Reservable Time</b-button
        >

        <div
          :style="{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            ...(breakpoint === 'desktop'
              ? {}
              : {
                  margin: '20px 0 0'
                })
          }"
        >
          <b-field label="Show" v-if="portal === 1">
            <b-select v-model="showing">
              <option v-for="option in homeOwnerDisplays" :value="option.value" :key="option.value">
                {{ option.text }}
              </option>
            </b-select>
          </b-field>

          <b-field label="Show" v-if="portal === 2">
            <b-select v-model="showing">
              <option
                v-for="option in managementDisplays"
                :value="option.value"
                :key="option.value"
              >
                {{ option.text }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>

      <PleasantCalendar
        ref="calendar"
        :pageChange="calendarUpdate"
        :dayTapped="dayTapped"
        :events="events"
        :breakpoint="breakpoint"
      />
    </div>

    <ReservabilityAdder ref="reservabilityAdder" :refreshParent="refreshParent" />
    <ReservationChooser ref="reservationChooser" :refreshParent="refreshParent" />
  </PageContent>
</template>

<script>
import PleasantCalendar from '@/components/Calendars/Pleasant'
import PageContent from '@/containers/PageContent'
import ReservabilityAdder from '@/pages/Management/amenities/ReservabilityAdder'
import ReservationChooser from '@/pages/Shared/amenities/ReservationChooser'

import { beforeDestroy } from './keys/beforeDestroy'
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { props } from './keys/props'
import { watch } from './keys/watch'

export default {
  components: {
    PageContent,
    PleasantCalendar,
    ReservabilityAdder,
    ReservationChooser
  },
  beforeDestroy,
  computed,
  data,
  methods,
  mounted,
  props,
  watch
}
</script>

<style lang="scss">
#amenities-details {
  .calendar-day-text {
    white-space: pre-wrap;
  }

  .custom-calendar {
    .vc-day {
      cursor: pointer;

      &:hover {
        outline: 2px solid #ddc4dd;
      }
    }
  }
}
</style>
