// /*
//   import { amenityFeeStore } from '@/services/Amenities/AmenityFee/store'
//})
// */

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

/* eslint-disable no-empty-pattern */
const actions = {
  //List
  async getAmenityFeeList({}, { amenityID }) {
    try {
      const params = {
        amenityID
      }

      const results = await kms.get('/Amenities/AmenityFee/List', { params })

      console.debug('getAmenityFeeList=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The Amenity Fee list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const amenityFeeStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
