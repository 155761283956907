<template>
  <div :style="{ display: 'flex', alignItems: 'center' }" class="time-picker--foundation">
    <b-select
      hour-select
      :style="{ width: '100px' }"
      placeholder="hour"
      v-model="hour"
      @input="onChange"
    >
      <option v-for="hour in hours" :value="hour" :key="hour">
        {{ hour }}
      </option>
    </b-select>

    <b-select
      minute-select
      :style="{ width: '120px' }"
      placeholder="minute"
      v-model="minute"
      @input="onChange"
    >
      <option v-for="minute in minutes" :value="minute" :key="minute">
        {{ minute }}
      </option>
    </b-select>

    <b-select
      meridian-select
      :style="{ width: '100px', marginLeft: '8px' }"
      placeholder="meridian"
      v-model="meridian"
      @input="onChange"
    >
      <option v-for="meridian in meridians" :value="meridian" :key="meridian">
        {{ meridian }}
      </option>
    </b-select>
  </div>
</template>

<style lang="scss">
@import '~@/assets/styles/_variables';

.time-picker--foundation {
  box-shadow: 0 4px 14px -5px rgba(0, 0, 0, 0.18);
  border-radius: 4px;

  select {
    border: 0;
  }

  &:focus {
    border-radius: 4px;
  }

  &.focused > .field-body {
    box-shadow: 0 4px 14px -5px rgba(0, 0, 0, 0.18), $tabable-focus;
  }

  & > .field-body {
    box-shadow: 0 4px 14px -5px rgba(0, 0, 0, 0.18);
    border-radius: 6px;
  }
}
</style>

<script>
/*
	import TimePicker from '@/components/inputs/TimePicker'

/////////////////////////

  <TimePicker v-model="time" />

  // valid -> update
  this.time = "04:00:00"
  this.time = "00:00:00"

  // invalid -> no update
  this.time = "4"
  
/////////////////////////

  <TimePicker v-model="time" />

  const time = this.$refs.time.getValue()[24]


*/

/*
	[HOUR] [MINUTE] [AM/PM]
*/

const hours = (() => {
  const hours = []

  hours.push('12')

  for (let a = 1; a <= 11; a++) {
    let hour = a.toString()
    if (hour.length === 1) {
      hour = '0' + hour
    }

    hours.push(hour)
  }

  return hours
})()

const minutes = (() => {
  const minutes = []

  for (let a = 0; a <= 59; a = a + 15) {
    let min = a.toString()
    if (min.length === 1) {
      min = '0' + min
    }

    minutes.push(min)
  }

  return minutes
})()

/*
	*****************

	12AM -> 00:
	1AM  -> 01:

	11AM -> 11:

	*****************

	12PM -> 12:
	1PM  -> 13:
	
	11PM -> 23:

	*****************

	00:00 to 23:59
*/
function parse24(time) {
  const hour = time.hour
  const minute = time.minute

  if (time.meridian === 'AM') {
    if (hour === '12') {
      return '00:' + minute + ':00'
    }

    return hour + ':' + minute + ':00'
  }
  if (time.meridian === 'PM') {
    if (hour === '12') {
      return '12:' + minute + ':00'
    }

    return (parseInt(hour) + 12).toString() + ':' + minute + ':00'
  }
}

import { timeIsInvalid } from '@/utilities/time/isInvalid'
import { toMeridian } from '@/utilities/time/toMeridian'

export default {
  props: {
    value: String,
    onUpdate: {
      type: Function,
      default() {}
    }
  },
  data() {
    return {
      hours,
      minutes,
      meridians: ['AM', 'PM'],
      //
      hour: null,
      minute: '00',
      meridian: 'AM'
    }
  },
  watch: {
    value() {
      this.setValue()
    }
  },
  methods: {
    setValue() {
      if (!timeIsInvalid(this.value)) {
        const { result } = toMeridian(this.value, {
          includeBreakdown: true
        })

        this.hour = result.hour
        this.minute = result.minute
        this.hour = result.hour
        this.meridian = result.meridian.toUpperCase()
      }
    },
    getValue() {
      const hour = this.hour
      const minute = this.minute
      const meridian = this.meridian

      let isValid = true
      if (!this.hours.includes(hour)) {
        isValid = false
      }

      return {
        isValid,
        24: parse24({
          hour,
          minute,
          meridian
        }),
        hour: this.hour,
        minute: this.minute,
        meridian: this.meridian
      }
    },
    onChange() {
      this.onUpdate(this.getValue())
    }
  },
  mounted() {
    this.setValue()
  }
}
</script>
