import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { notifyMessage, notifyProblem, notifyError } from '@/services/notify'
import { addAmenityReservation } from '@/services/Amenities/AmenityReservation/Post'
import { deleteAmenityReservableTime } from '@/services/Amenities/AmenityReservableTime/Delete'
import { deleteAmenityReservation } from '@/services/Amenities/AmenityReservation/Delete'
import { findMultipleReservations } from '@/services/Amenities/AmenityReservation/sequences/findMultipleReservations'
import { deleteMultipleReservations } from '@/services/Amenities/AmenityReservation/sequences/deleteMultiple'
import { addReservableTime } from '@/services/Amenities/AmenityReservableTime/Post'
import { approveAmenityReservation } from '@/services/Amenities/AmenityReservation/ApproveReservation'
import { amenityStore } from '@/services/Amenities/Amenity/store'
import { amenityFeeStore } from '@/services/Amenities/AmenityFee/store'
import { getTimeSpans } from '@/utilities/time/getSpans'
import { compareTimes } from '@/utilities/time/compare'
import { timeIsAvailable } from '@/utilities/time/isAvailable'
import { monthNums } from '@/utilities/Date/constants'
import { toMeridian } from '@/utilities/time/toMeridian'
import { findReliantReservations } from '@/utilities/amenities/findReliantReservations'
import { getDuration } from '@/utilities/time/getDuration'
import { reservationIsApproved } from '@/utilities/amenities/reservationIsApproved'
import { getArray } from '@/utilities/get/Array'
import { outstandingPayment } from '@/services/Payments/HomeOwner/OutstandingPayment/store'
import { amenityReservationStore } from '@/services/Amenities/AmenityReservation/store'
import { epicGateway } from '@/services/EpicGateway/store'
import luhn from 'luhn'

export const methods = {
  _get,
  toMeridian,
  reservationIsApproved,

  back() {
    this.showing = 1
  },
  show(num) {
    this.showing = num
    this.$forceUpdate()
  },
  async closeModal() {
    this.isOpen = false
  },

  async approveReservationRequest({ reservation }) {
    this.loading = true

    const amenityData = this.amenityData

    const amenityReservationID = _get(reservation, [3, 'amenityReservationID'], null)
    const amenityReservableTimes = _get(amenityData, ['amenityReservableTimes'], null)
    const amenityReservations = _get(amenityData, ['amenityReservations'], null)

    const { successful, message } = await approveAmenityReservation({
      amenityReservationID
    })
    if (!successful) {
      notifyProblem(message)
      return
    } else {
      console.debug('reservation=' + JSON.stringify(reservation))
      if (reservation[3] !== undefined && reservation[3].chargeAmount !== undefined) {
        let chargedAmount =
          reservation[3].chargeAmount +
          reservation[3].nonRefundableAmount +
          this.convenienceFeeFromPercent(
            reservation[3].chargeAmount + reservation[3].nonRefundableAmount,
            reservation[3].convenienceFeeAmount
          )

        notifyMessage(
          `The home owner's card was charged, $` +
            `${chargedAmount ||
              0}. The home owner 'Billing Account Activity' section will show when the payment is processed.`
        )
      }
    }

    this.parseReservableTimes({
      list: amenityReservableTimes
    })

    await this.parseReservations({
      list: amenityReservations
    })

    this.determineAvailability()

    this.loading = false
  },

  //	When modal is opened
  parseReservableTimes({ list }) {
    const dayOfWeek = this.dayOfWeek

    const filtered = list.filter(entry => {
      return _get(entry, 'dayOfWeek', '') === dayOfWeek
    })

    const parsed = filtered.map(entry => {
      return [_get(entry, 'startTime', ''), _get(entry, 'endTime', ''), entry]
    })

    const { spans } = getTimeSpans({
      availabilities: filtered.map(entry => {
        return [_get(entry, 'startTime', ''), _get(entry, 'endTime', ''), entry]
      })
    })

    // this is the calculated availability
    this.spans = spans

    this.reservableTimes = parsed
    this.filteredReservableTimes = filtered
  },

  async parseReservations({ list }) {
    const mDate = this.mDate

    const filtered = list.filter(entry => {
      const date = moment(entry.startDate)
      const year = date.year()
      const month = date.month() + 1
      const dayOfMonth = date.date()

      if (year == mDate.year && month == mDate.month && dayOfMonth == mDate.dayOfMonth) {
        return true
      }

      return false
    })

    const reservationIDs = []
    for (let a = 0; a < filtered.length; a++) {
      reservationIDs.push(_get(filtered, [a, 'amenityReservationID'], null))
    }

    const { reservations, problems } = await findMultipleReservations({
      reservationIDs
    })
    if (problems.length >= 1) {
      notifyProblem(`The details about ${problems.length} could not be retrieved successfully.`)
    }

    this.reservations = reservations.map(entry => {
      return [
        moment(entry.startDate).format('HH:mm:ss'),
        moment(entry.endDate).format('HH:mm:ss'),
        _get(entry, ['identityInformation', 'name'], ''),
        entry
      ]
    })

    this.filteredReservations = filtered
  },
  determineAvailability() {
    const { openings } = getTimeSpans({
      availabilities: this.spans,
      reservations: this.reservations
    })

    this.openings = openings
  },
  async open({ amenityData, dayOfMonth, month, year }) {
    this.loading = true

    this.mDate = {
      year,
      month,
      monthStr: monthNums[month],
      dayOfMonth
    }

    const authUser = _get(this, ['$store', 'getters', 'user/authUser'], null)

    this.ownerID = this.ownerId ? this.ownerId : _get(authUser, ['ownerID'], null)

    this.showing = 1
    this.toDelete = {}
    this.reservableTimes = []
    this.spans = []
    this.openings = []
    this.reservations = []
    this.allowUpdate = 0
    this.affectedReservations = []

    this.date = moment(`${year}-${month}-${dayOfMonth}`, 'YYYY-MM-DD')
    this.dayOfWeek = this.date.format('dddd')
    this.amenityData = amenityData

    this.parseReservableTimes({
      list: getArray(amenityData, 'amenityReservableTimes', [])
    })

    await this.parseReservations({
      list: getArray(amenityData, 'amenityReservations', [])
    })

    this.determineAvailability()

    console.debug('in open amenityData=' + JSON.stringify(this.amenityData.amenityID))

    await this.loadAmenityFees(this.amenityData.amenityID)

    this.isOpen = true
    this.loading = false
  },

  //	Cancelling Reservations
  async confirmCancelReservation({ reservation }) {
    this.showing = 6
    this.reservationToCancel = reservation[3]

    if (
      this.reservationToCancel.convenienceFeeAmount !== undefined &&
      this.reservationToCancel.convenienceFeeAmount
    ) {
      this.convenienceFee = this.convenienceFeeFromPercent(
        this.reservationToCancel.chargeAmount + this.reservationToCancel.nonRefundableAmount,
        this.reservationToCancel.convenienceFeeAmount || 0
      )
      console.debug('this.convenienceFee=' + this.convenienceFee)
    }
  },

  convenienceFeeFromPercent(amount, percent) {
    if (amount && percent) {
      try {
        const percentage = percent * 0.01
        const percentAmount = amount * percentage
        return percentAmount
      } catch (error) {
        console.debug('error in convenienceFeeFromPercent=' + error)
        return 0
      }
    }
  },

  async cancelReservation(isOrphan) {
    this.loading = true

    const { successful, message } = await deleteAmenityReservation({
      amenityReservationID: this.reservationToCancel.amenityReservationID
    })

    if (!successful) {
      notifyProblem(message)
      this.loading = false
      return
    }

    amenityStore.commit('amenityReservationCancelled')
    await new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, 300)
    }).then(() => {
      if (this.reservationIsApproved({ reservation: this.reservationToCancel })) {
        console.debug('cancelWithRefund in chooser...')
        const payload = {
          amenityReservationID: this.reservationToCancel.amenityReservationID
        }

        let chargeAmount = 0
        let nonRefundableAmount = 0

        if (
          this.reservationToCancel !== undefined &&
          this.reservationToCancel &&
          this.reservationToCancel.chargeAmount
        ) {
          chargeAmount = this.reservationToCancel.chargeAmount
        }

        if (
          this.reservationToCancel.nonRefundableAmount !== undefined &&
          this.reservationToCancel.nonRefundableAmount
        ) {
          nonRefundableAmount = this.reservationToCancel.nonRefundableAmount

          if (
            this.reservationToCancel.convenienceFeeAmount !== undefined &&
            this.reservationToCancel.convenienceFeeAmount
          ) {
            nonRefundableAmount = nonRefundableAmount + this.convenienceFee
          }
        }

        amenityReservationStore.dispatch('cancelWithRefund', {
          payload,
          done: detail => {
            if (detail) {
              console.debug('refunded cancellation= ' + JSON.stringify(detail))

              if (chargeAmount > 0) {
                var formatter = new Intl.NumberFormat('en-US', {
                  style: 'decimal',
                  minimumFractionDigits: 2
                })

                let notifymessage =
                  `This reservation was successfully cancelled with a refund of, $` +
                  formatter.format(chargeAmount) +
                  `. `
                if (nonRefundableAmount > 0) {
                  notifymessage =
                    notifymessage +
                    `There is a non-refundable amount for, $` +
                    formatter.format(nonRefundableAmount) +
                    `.`
                }

                notifyMessage(notifymessage)
              } else {
                notifyMessage('This reservation has been successfully cancelled with a refund.')
              }
            }
          }
        })
      } else {
        if (isOrphan === undefined || !isOrphan) {
          notifyMessage('This reservation has been successfully cancelled.')
        }
      }
    })

    this.loading = false
    this.reservationToCancel = null
    this.refreshParent('cancelandrefund')
    this.isOpen = false
  },

  //	Making Reservations or Reservation Requests
  async ensureIsAvailable({ t1, t2 }) {
    const comparison = compareTimes(t2, t1)

    if (comparison === 0) {
      notifyProblem("The reservation can't start and end at the same time.")
      this.loading = false
      return
    }
    if (comparison === 2) {
      notifyProblem("The reservation can't end before it starts.")
      this.loading = false
      return
    }
    if (comparison >= 3) {
      notifyProblem('Please enter proper times for the start and end times.')
      console.error({ t1, t2, comparison })
      this.loading = false
      return
    }

    // const [sHour, sMinute, sSecond] = t1.split(':')
    // const [eHour, eMinute, eSecond] = t2.split(':')

    const amenityData = this.amenityData
    const reservationDurationLimitInMinutes = _get(
      amenityData,
      'reservationDurationLimitInMinutes',
      0
    )

    if (
      typeof reservationDurationLimitInMinutes === 'number' &&
      reservationDurationLimitInMinutes > 0
    ) {
      const proposedDurationSeconds = getDuration([t1, t2])
      if (typeof proposedDurationSeconds !== 'number' || proposedDurationSeconds <= 0) {
        notifyProblem(
          `A problem occurred and the duration of the reservation could not be determined.`
        )
        this.loading = false
        return
      }

      const reservationDurationLimitInSeconds = reservationDurationLimitInMinutes * 60
      if (reservationDurationLimitInSeconds < proposedDurationSeconds) {
        notifyProblem(
          `Reservations for this amenity cannot exceed ${reservationDurationLimitInMinutes} minutes.`
        )
        this.loading = false
        return
      }
    }

    const {
      available // boolean
    } = timeIsAvailable({
      check: [t1, t2],
      availabilities: this.openings,
      reservations: []
    })
    if (!available) {
      notifyProblem("That time slot doesn't seem to be available.")
      this.loading = false
      return
    }

    return true
  },
  async reserve() {
    this.loading = true

    const t1 = this.$refs.fromTime.getValue()[24]
    const t2 = this.$refs.toTime.getValue()[24]

    const isAvailable = await this.ensureIsAvailable({ t1, t2 })
    if (isAvailable !== true) {
      return
    }

    const reservationOwnerID = this.ownerId
      ? this.ownerId
      : _get(this, ['$store', 'getters', 'user/authUser', 'ownerID'], null)
    let { year, month, dayOfMonth } = this.mDate

    month = month.toString()
    if (month.length === 1) {
      month = '0' + month
    }

    dayOfMonth = dayOfMonth.toString()
    if (dayOfMonth.length === 1) {
      dayOfMonth = '0' + dayOfMonth
    }

    const startDate = `${year}-${month}-${dayOfMonth}T${t1}`
    const endDate = `${year}-${month}-${dayOfMonth}T${t2}`

    const { successful, message } = await addAmenityReservation({
      json: {
        amenityID: _get(this, ['amenityData', 'amenityID'], null),
        reservationOwnerID,
        startDate,
        endDate,
        reservationIdentityID: '_',
        notes: 'notes about the reservation',
        amenityReservation: '?'
      }
    })
    if (!successful) {
      notifyProblem(message)
      this.loading = false
      return
    }

    amenityStore.commit('amenityReservationAdded')

    this.loading = false
    this.isOpen = false
  },

  async onFormSubmit() {
    let validationMessages = ''

    console.debug('in onFormSubmit...' + this.amenityPaymentMethodType)

    if (!this.amenityID || !this.reservationOwnerID || !this.startDate || !this.endDate) {
      validationMessages = `Please press 'Back' and select a start and end time for your amenity.`
    }

    //validation
    if (this.amenityPaymentMethodType) {
      if (this.amenityPaymentMethodType === 'stored') {
        if (!this.ownerPaymentMethodID) {
          validationMessages =
            validationMessages +
            'Selecting a stored credit card is required to proceed with this transaction.'
        }
      } else if (this.amenityPaymentMethodType === 'creditcard') {
        //Validate credit card number with luhn
        var isValid = luhn.validate(this.creditCardNumber)

        if (isValid == false) {
          notifyError('Please enter a valid credit card number.')
          return
        }

        if (!this.creditCardNumber) {
          validationMessages = validationMessages + 'The credit card number is required.'
        }

        if (!this.cardHolderName) {
          validationMessages = validationMessages + ' The credit card holder name is required.'
        }

        const expMonthYear = this.$refs.cardExpirationDateComponent.getValue()
        if (!expMonthYear.month) {
          validationMessages = validationMessages + ' The expiration month is required.'
        }

        if (!expMonthYear.year) {
          validationMessages = validationMessages + ' The expiration year is required.'
        }

        if (!this.cvc) {
          validationMessages = validationMessages + ' The credit card security code is required.'
        }
      }

      if (this.amenityFeeTotal === 0) {
        validationMessages =
          validationMessages +
          ' The total fee must be a non-zero value for a transaction to take place.'
      }

      if (validationMessages !== '') {
        notifyProblem(validationMessages)
        return
      }

      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      try {
        //Add the Amenity Reservation
        const { successful, message, result } = await addAmenityReservation({
          json: {
            amenityID: this.amenityID,
            reservationOwnerID: this.reservationOwnerID,
            startDate: this.startDate,
            endDate: this.endDate,
            reservationIdentityID: '_',
            notes: '_',
            amenityReservation: '_'
          }
        })
        if (!successful) {
          notifyProblem(message)
          this.loading = false
          loadingComponent.close()
          return
        } else {
          this.newAmenityReservation = result
        }

        await this.getFortisJwtOneTimeToken()

        if (this.amenityPaymentMethodType && this.amenityPaymentMethodType == 'stored') {
          let payload = {
            ownerID: this.ownerId ? this.ownerId : this.authUser ? this.authUser.ownerID : 0,
            paymentAmount: this.amenityFeeTotal || 0,
            ownerPaymentMethodID: this.ownerPaymentMethodID || 0,
            isApprovalPending: false
          }

          if (this.isApprovalPending()) {
            payload.isApprovalPending = true

            const params = {
              ownerPaymentMethodID: this.ownerPaymentMethodID,
              tokenNumber: '',
              amenityReservationID: this.newAmenityReservation.amenityReservationID,
              isApprovalPending: true
            }

            await amenityReservationStore.dispatch('updateAmenityReservationForPaymentOnApproval', {
              params,
              done: ({ detail }) => {
                if (detail !== undefined && detail) {
                  this.loading = false
                  this.resetFormValues()
                  loadingComponent.close()

                  const totalWithConvenienceFee =
                    (this.amenityFeeTotal || 0) + (this.convenienceFee || 0)

                  var formatter = new Intl.NumberFormat('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 2
                  })

                  notifyMessage(
                    `Your credit card will be charged, $` +
                      `${formatter.format(
                        totalWithConvenienceFee
                      )}, when a board manager approves this reservation.`
                  )
                  this.resetFormValues()
                  this.isOpen = false
                  loadingComponent.close()
                }
              }
            })
          } else {
            console.debug('addSendPayment payload=' + JSON.stringify(payload))

            await outstandingPayment.dispatch('addSendPayment', {
              payload,
              done: ({ invalidMessage }) => {
                if (invalidMessage !== undefined && invalidMessage && invalidMessage.length > 0) {
                  notifyError(invalidMessage)
                  return
                } else {
                  this.resetFormValues()
                  loadingComponent.close()

                  const totalWithConvenienceFee =
                    (this.amenityFeeTotal || 0) + (this.convenienceFee || 0)

                  var formatter = new Intl.NumberFormat('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 2
                  })

                  notifyMessage(
                    `Your credit card will be charged, $` +
                      `${formatter.format(totalWithConvenienceFee)} on the selected credit card.`
                  )
                  this.resetFormValues()
                  this.isOpen = false
                  loadingComponent.close()
                }
              }
            })
          }
        } else if (this.amenityPaymentMethodType && this.amenityPaymentMethodType == 'creditcard') {
          const lastFourDigits = this.creditCardNumber
            .toString()
            .slice(
              this.creditCardNumber.toString().length - 4,
              this.creditCardNumber.toString().length
            )

          const expMonthYear = this.$refs.cardExpirationDateComponent.getValue()

          const registerParams = {
            account_number: this.creditCardNumber || '',
            method: 'credit_card'
          }

          await outstandingPayment
            .dispatch('registerOneTimeToken', {
              params: registerParams,
              jwt: this.requestJwtToken,
              amount: this.amenityFeeTotal || 0,
              name: this.cardHolderName || '',
              expMonth: expMonthYear.month || '',
              expYear: expMonthYear.year || '',
              cvv: this.cvc || ''
            })
            .then(async ({ result }) => {
              if (result && result.status !== undefined && result.status) {
                notifyError(result.status)
                return
              } else if (result) {
                const params = {
                  method: 'card_token',
                  ownerUserID: this.authUser.ownerUserID,
                  paymentMethodType: 'Credit Card',
                  lastFourDigits: lastFourDigits,
                  paymentAmount: this.amenityFeeTotal || 0,
                  token: result.token_number || '',
                  tokenType: result.token_type || '',
                  cardHolderName: this.cardHolderName || '',
                  expMonth: expMonthYear.month || '',
                  expYear: expMonthYear.year || '',
                  savePaymentMethod: this.addCreditCardPaymentMethod || false,
                  accountAlias: this.accountAliasCreditCard || '',
                  postalCode: this.zipCode || ''
                }

                //Register a second one-time token for saving this payment method
                await outstandingPayment
                  .dispatch('registerOneTimeToken', {
                    params: registerParams,
                    jwt: this.requestJwtToken,
                    amount: this.amenityFeeTotal || 0,
                    name: this.cardHolderName || '',
                    expMonth: expMonthYear.month || '',
                    expYear: expMonthYear.year || '',
                    cvv: this.cvc || ''
                  })
                  .then(async ({ result }) => {
                    if (result && result.status !== undefined && result.status) {
                      notifyError(result.status)
                      return
                    } else if (result) {
                      params.savePaymentToken = result.token_number

                      if (this.isApprovalPending()) {
                        params.isApprovalPending = true
                        params.amenityReservationID = this.newAmenityReservation.amenityReservationID
                        params.savePaymentMethod = true

                        await epicGateway.dispatch('addDeferredOwnerPaymentMethodCreditCard', {
                          params,
                          done: async ({ details }) => {
                            //auto-close modal
                            if (details) {
                              this.loading = false
                              this.resetFormValues()
                              loadingComponent.close()
                              totalWithConvenienceFee

                              const totalWithConvenienceFee =
                                (this.amenityFeeTotal || 0) + (this.convenienceFee || 0)

                              var formatter = new Intl.NumberFormat('en-US', {
                                style: 'decimal',
                                minimumFractionDigits: 2
                              })

                              notifyMessage(
                                `Your credit card will be charged, $` +
                                  `${formatter.format(
                                    totalWithConvenienceFee
                                  )}, when a board manager approves this reservation.`
                              )
                              this.resetFormValues()
                              this.isOpen = false
                              loadingComponent.close()
                            }
                          }
                        })
                      } else {
                        await outstandingPayment.dispatch('addOneTimeCreditCardPayment', {
                          payload: params,
                          done: ({ invalidMessage }) => {
                            if (
                              invalidMessage !== undefined &&
                              invalidMessage &&
                              invalidMessage.length > 0
                            ) {
                              notifyError(invalidMessage)
                              return
                            } else {
                              this.loading = false
                              this.resetFormValues()
                              loadingComponent.close()
                              const totalWithConvenienceFee =
                                (this.amenityFeeTotal || 0) + (this.convenienceFee || 0)

                              notifyMessage(
                                `Your credit card was charged, $` +
                                  `${totalWithConvenienceFee}. Check your 'Billing Account Activity' section to see when the payment is processed.`
                              )
                              this.resetFormValues()
                              this.isOpen = false
                              loadingComponent.close()
                            }
                          }
                        })
                      }
                    }
                  })
              }
            })
        }
      } catch (e) {
        notifyError('There was a problem adding your payment method for a credit card.' + e)
        loadingComponent.close()
      }

      loadingComponent.close()
      this.refreshParent('chooser')
    }
  },

  isApprovalPending() {
    const requiresApproval =
      _get(this.amenityData, ['requiresApproval'], false) &&
      !reservationIsApproved({ reservation: _get(this.reservation, [3], null) })

    console.debug('requires approval=' + requiresApproval)

    return requiresApproval
  },

  async getFortisJwtOneTimeToken() {
    await outstandingPayment
      .dispatch('getOneTimeFortisJwtToken', {
        hoaID: this.hoaId
      })
      .then(({ result }) => {
        if (result) {
          if (this.isDebug === true)
            console.debug('getOneTimeFortisJwtToken' + JSON.stringify(result))

          if (result) {
            this.requestJwtToken = result
          }
        }
      })
  },

  async loadServiceFees() {
    console.debug('in loadServices...')

    const params = {
      hoaID: this.hoaId
    }

    await outstandingPayment
      .dispatch('getServiceFees', {
        params: params
      })
      .then(({ result }) => {
        if (result) {
          const percentage = Number.parseFloat(result.creditCardPercentageServiceFee) * 0.01
          console.debug('percentage=' + percentage)
          const creditCardPercentage = percentage * this.amenityFeeTotal || 0
          console.debug('creditCardPercentage=' + creditCardPercentage)
          console.debug('amenityPaymentMethodType=' + this.amenityPaymentMethodType)
          this.convenienceFee = creditCardPercentage
          console.debug('creditCardPercentage...' + creditCardPercentage)
        }
      })
  },

  async requestReservation(continueToFees) {
    this.loading = true

    console.debug('mDate=' + JSON.stringify(this.mDate))

    const t1 = this.$refs.fromTime.getValue()[24]
    const t2 = this.$refs.toTime.getValue()[24]

    const isAvailable = await this.ensureIsAvailable({ t1, t2 })
    if (isAvailable !== true) {
      this.loading = false
      return
    }

    const reservationOwnerID = this.ownerId
      ? this.ownerId
      : _get(this, ['$store', 'getters', 'user/authUser', 'ownerID'], null)
    let { year, month, dayOfMonth } = this.mDate

    month = month.toString()
    if (month.length === 1) {
      month = '0' + month
    }

    dayOfMonth = dayOfMonth.toString()
    if (dayOfMonth.length === 1) {
      dayOfMonth = '0' + dayOfMonth
    }

    const startDate = `${year}-${month}-${dayOfMonth}T${t1}`
    const endDate = `${year}-${month}-${dayOfMonth}T${t2}`

    if (month === '' || year === '') {
      notifyProblem('Please enter a start and end dates for the new reservation request.')
      return
    }

    //Instead of Adding this, store in session
    if (continueToFees !== undefined && continueToFees === true) {
      //Store an amenity reservation in session
      this.$store.dispatch('amenity/saveAmenityID', {
        amenityID: _get(this, ['amenityData', 'amenityID'], null)
      })
      this.$store.dispatch('amenity/saveReservationOwnerID', {
        reservationOwnerID
      })
      this.$store.dispatch('amenity/saveStartDate', {
        startDate
      })
      this.$store.dispatch('amenity/saveEndDate', {
        endDate
      })

      console.debug('session state=' + this.amenityID)

      this.reservationFees()
    } else {
      const { successful, message } = await addAmenityReservation({
        json: {
          amenityID: _get(this, ['amenityData', 'amenityID'], null),
          reservationOwnerID,
          startDate,
          endDate,
          reservationIdentityID: '_',
          notes: '_',
          amenityReservation: '_'
        }
      })
      if (!successful) {
        notifyProblem(message)
        this.loading = false
        return
      }

      amenityStore.commit('amenityReservationAdded')
      this.refreshParent()
      notifyMessage('The reservation request has been sent.')

      this.loading = false
      this.isOpen = false
    }

    await this.loadServiceFees()
  },

  async checkBeforeClose() {
    console.debug('checkBeforeClose()...')

    if (
      this.newAmenityReservation !== undefined &&
      this.newAmenityReservation &&
      !this.newAmenityReservation.ownerPaymentMethodID
    ) {
      //Remove the orphan
      this.reservationToCancel = this.newAmenityReservation
      this.cancelReservation(true)
    }
  },

  resetFormValues() {
    this.creditCardNumber = ''
    this.cardHolderName = ''
    this.cvc = ''
    this.zipCode = ''
    this.accountAliasCreditCard = ''
    this.ownerPaymentMethodID = 0
    this.newAmenityReservation = null
    this.amenityPaymentMethodType = ''
    this.$store.dispatch('amenity/initStates')
  },

  async reservationFees() {
    this.loading = true

    console.debug('reservationFees...' + this.isApprovalPending())
    this.showing = 8

    this.loadAmenityFees()

    this.loading = false
  },

  async loadPaymentMethodTypes() {
    this.loading = true
    await amenityStore.dispatch('getAmenityFeePaymentMethodTypes').then(t => {
      if (t && t.list && t.list != undefined) {
        this.amenityPaymentMethodTypes = t.list
      }
    })
    this.loading = false
  },

  async loadAmenityFees(amenityID) {
    this.loading = true

    console.debug('amenityData=' + JSON.stringify(this.amenityData))

    if (amenityID <= 0) {
      amenityID = _get(this, ['amenityData', 'amenityID'], null)
    }

    if (amenityID > 0) {
      try {
        await amenityFeeStore
          .dispatch('getAmenityFeeList', {
            amenityID
          })
          .then(t => {
            console.debug('amenity fees=' + JSON.stringify(t))

            if (t && t.list && t.list != undefined) {
              this.amenityFees = t.list
            }

            this.amenityFeeTotal = this.amenityFees.reduce((acc, item) => acc + item.amount, 0)
          })
      } catch (error) {
        console.debug(error.message)
      }
    }
    this.loading = false
  },

  async loadPaymentMethods() {
    const selectedOwnerUser = this.authUser.ownerUsers.filter(f => f.ownerID === this.ownerId)
    const ownerUserID =
      selectedOwnerUser[0] !== undefined && selectedOwnerUser[0]
        ? selectedOwnerUser[0].ownerUserID
        : this.authUser
        ? this.authUser.ownerUserID
        : 0

    if (ownerUserID !== undefined && ownerUserID > 0) {
      const params = {
        ownerUserID: ownerUserID
      }

      await outstandingPayment
        .dispatch('getPaymentMethodList', {
          params: params
        })
        .then(({ list }) => {
          if (list) {
            this.paymentMethods = list.filter(
              f => f !== undefined && f && f.label.indexOf('Credit Card') > -1
            )
          }
        })
    }
  },

  //	Deleting Reservable Times
  confirmDeleteReservableTime({ reservableTime }) {
    const reservableTimeDayOfWeek = _get(reservableTime, [2, 'dayOfWeek'], null)

    const amenityReservableTimeID = _get(reservableTime, [2, 'amenityReservableTimeID'], null)
    const allReservations = this.amenityData.amenityReservations
    const reservableTimes = this.amenityData.amenityReservableTimes.filter(r => {
      const id = _get(r, 'amenityReservableTimeID', '')
      if (typeof id === 'number' && id === amenityReservableTimeID) {
        return false
      }

      return true
    })

    const { reliantReservations } = findReliantReservations({
      amenityReservableTimeID,
      reservableTimes,
      reservations: allReservations
    })

    this.toDelete = {
      reservableTimeDayOfWeek,
      startTime: toMeridian(_get(reservableTime, [2, 'startTime'], null)),
      endTime: toMeridian(_get(reservableTime, [2, 'endTime'], null)),
      amenityReservableTimeID,
      reliantReservations: reliantReservations.map(rr => {
        return [
          moment(rr.startDate).format('LLLL'),
          moment(rr.endDate).format('LLLL'),
          _get(rr, ['identityInformation', 'name'], '__'),
          rr
        ]
      })
    }
    this.showing = 2
  },

  async deleteReservableTime() {
    this.loading = true

    const toDelete = this.toDelete
    const reliantReservations = toDelete.reliantReservations

    const problems = []
    let successes = 0
    for (let a = 0; a < reliantReservations.length; a++) {
      const reliantReservation = reliantReservations[a]
      const amenityReservationID = _get(reliantReservations, [a, 3, 'amenityReservationID'], null)

      if (typeof amenityReservationID !== 'number') {
        problems.push(reliantReservation)
        continue
      }

      const { successful, message } = await deleteAmenityReservation({
        amenityReservationID
      })
      if (!successful) {
        problems.push(reliantReservation, message)
        continue
      }

      successes++
    }

    const { amenityReservableTimeID } = toDelete
    const { successful, message } = await deleteAmenityReservableTime({
      amenityReservableTimeID
    })
    if (!successful) {
      notifyProblem(message)
      return
    }

    amenityStore.commit('amenityReservableTimeRemoved')

    this.deleted = {
      problems,
      successes
    }

    this.loading = false
    this.showing = 3
  },

  //	Editing Reservable Times
  //
  //    edit button -> confirmEditReservableTime
  //
  //    updaterOnUpdate
  //      called every time a time input is changed
  confirmEditReservableTime({ reservableTime }) {
    this.toUpdate = {
      startTime: reservableTime[0],
      endTime: reservableTime[1],
      dayOfWeek: reservableTime[2].dayOfWeek,
      reservableTime,
      proposal: {
        startTime: null,
        endTime: null
      }
    }

    this.showing = 4
  },

  /*
		remove the reservable time in question,
		add the new reservable time,

			determine if all reservations are still available or not
	*/
  updaterOnUpdate() {
    this.affectedReservations = []
    this.affectedReservationsDetails = []

    const editFromTime = this.$refs.editFromTime.getValue()
    const editToTime = this.$refs.editToTime.getValue()

    if (!editFromTime.isValid || !editToTime.isValid) {
      console.debug('time is not valid')
      return
    }

    // is start time before end time?
    const comparison = compareTimes(editFromTime[24], editToTime[24])
    if (comparison !== 2) {
      this.allowUpdate = 2
      return
    }

    const reservableTime = _get(this, ['toUpdate', 'reservableTime', 2], null)
    const idToRemove = _get(reservableTime, ['amenityReservableTimeID'], null)
    const reservableTimes = this.amenityData.amenityReservableTimes.filter(r => {
      const id = _get(r, 'amenityReservableTimeID', '')
      if (typeof id === 'number' && id === idToRemove) {
        return false
      }

      return true
    })

    reservableTimes.push({
      amenityReservableTimeID: null,
      dayOfWeek: this.toUpdate.dayOfWeek,
      startTime: editFromTime[24],
      endTime: editToTime[24]
    })

    const { reliantReservations } = findReliantReservations({
      amenityReservableTimeID: 0,
      reservableTimes,
      reservations: this.amenityData.amenityReservations
    })

    this.toUpdate.proposal = {
      dayOfWeek: this.toUpdate.dayOfWeek,
      startTime: editFromTime[24],
      endTime: editToTime[24]
    }

    /*
		    determines if any of the reservations would be cancelled
			  because of the edit
		*/
    this.affectedReservations = _cloneDeep(reliantReservations).map(rr => {
      return [
        moment(rr.startDate).format('LLLL'),
        moment(rr.endDate).format('LLLL'),
        _get(rr, ['identityInformation', 'name'], '__'),
        rr
      ]
    })

    this.allowUpdate = 1
  },
  confirmUpdateReservableTime() {
    this.showing = 5
  },
  async updateReservableTime() {
    this.loading = true

    /*
			Delete affected reservations
		*/
    const ids = []
    const affectedReservations = this.affectedReservations
    for (let a = 0; a < affectedReservations.length; a++) {
      ids.push(affectedReservations[a][3].amenityReservationID)
    }

    const { problems } = await deleteMultipleReservations({
      reservationIDs: ids
    })
    if (problems.length >= 1) {
      notifyProblem(`${problems.length} of the reservations were not cancelled.`)
      return
    }

    const reservableTime = _get(this, ['toUpdate', 'reservableTime', 2], null)
    const amenityReservableTimeID = _get(reservableTime, ['amenityReservableTimeID'], null)

    const reservableTimeProposal = _get(this, ['toUpdate', 'proposal'], null)
    const dayOfWeek = _get(reservableTimeProposal, ['dayOfWeek'], null)
    const startTime = _get(reservableTimeProposal, ['startTime'], null)
    const endTime = _get(reservableTimeProposal, ['endTime'], null)

    /*
			Update reservable time
		*/
    const { successful, message } = await deleteAmenityReservableTime({
      amenityReservableTimeID
    })
    if (!successful) {
      notifyProblem(message)
    }

    const { successful: addedSuccessfully, message: addMessage } = await addReservableTime({
      json: {
        // amenityReservableTimeID,
        amenityID: _get(this, ['amenityData', 'amenityID'], null),
        dayOfWeek,
        startTime,
        endTime,
        cleanupDurationInMinutes: 0
      }
    })
    if (!addedSuccessfully) {
      notifyProblem(addMessage)
    }

    notifyMessage(
      `The reservable time has been updated.  ${ids.length} reservation${
        ids.length === 1 ? '' : 's'
      } or reservation request${ids.length === 1 ? '' : 's'} have been cancelled.`
    )

    amenityStore.commit('amenityReservationAdded')

    this.isOpen = false
    this.loading = false
  }
}
