/*
 import { getTimeSpans } from '@/utilities/time/getSpans'

	const { spans, openings } = getTimeSpans ({
		availabilities: [
			[ from, to ],
			[ from, to ],
			[ from, to ],
			[ from, to ]
		],
		reservations: [
			[ from, to ]
		]
	})
*/

/*
	spans -> condensed availabilities
		for example:
			availabilities:
				00:00:00 to 04:00:00
				00:00:00 to 05:00:00

			spans -> [ 00:00:00, 05:00:00 ]

	openings -> spans without reservations
			availabilities:
				00:00:00 to 04:00:00
				00:00:00 to 05:00:00

			reservations:
				02:00:00 to 03:00:00

			openings -> [
				[ 00:00:00, 02:00:00 ],
				[ 03:00:00, 05:00:00 ]
			]
*/

/*
	e.g.
		"08:00:00", "10:00:00"
		"07:00:00", "09:00:00"

		-> [ 
				[ "07:00:00", "10:00:00" ]
			]
*/

import cloneDeep from 'lodash/cloneDeep'

import { timeIsInvalid } from '@/utilities/time/isInvalid'
import { compareTimes } from '@/utilities/time/compare'

function determineSpans({ availabilities }) {
  try {
    const issues = []
    const spans = []

    // l -> location
    for (let l = 0; l < availabilities.length; l++) {
      const [b1, b2] = availabilities[l]
      const b = availabilities[l]

      const b1Invalid = timeIsInvalid(b1)
      if (b1Invalid) {
        issues.push({
          invalid: l,
          b1Invalid
        })
        console.debug(b1Invalid)
        continue
      }
      const b2Invalid = timeIsInvalid(b2)
      if (b2Invalid) {
        issues.push({
          invalid: l,
          b2Invalid
        })
        console.debug({
          b,
          invalid: l,
          b2Invalid
        })
        continue
      }

      if (spans.length === 0) {
        spans.push(b)
        continue
      }

      /*
				looking for overlaps

					possibilities:
						no overlaps -> add new span
						one overlap -> join spans
						2+ overlaps -> join all spans

				example:
					new: 5 to 9

					spans:
						4 to 6 -> [ 4 to 9, 8 to 10 ] after first iteration
						8 to 10 -> [ 4 to 10 ] after second iteration
			*/
      let add = true
      for (let l2 = 0; l2 < spans.length; l2++) {
        const a = spans[l2]
        const [a1, a2] = a

        const comparisons = {
          1: compareTimes(a1, b1),
          2: compareTimes(a1, b2),
          3: compareTimes(a2, b1),
          4: compareTimes(a2, b2)
        }

        const a1b1 = comparisons[1]
        const a1b2 = comparisons[2]
        const a2b1 = comparisons[3]
        const a2b2 = comparisons[4]

        /*
					TODO:
						check if comparison is indeterminable,
						if any are indeterminable 
						(that is the times are invalid..., etc.?)
						then.....

							skip... and add the issue

						invalid times should have been noticed earlier
						in the l1 (outer) loop though.
				*/

        /*
					a1 === b1
				*/
        if (a1b1 === 0) {
          const a2b2 = comparisons[4]

          //
          /*
						a2 === b2 -> ignore b
							a: |
							b: |

							a: [ ]
							b: [ ]
					*/
          if (a2b2 === 0) {
            add = false
            break
          }

          /*
						a2 > b2 -> ignore b
							a: [  ]
							b: [ ]
					*/
          if (a2b2 === 1) {
            add = false
            continue
          }

          /*
						a2 < b2 -> overwrite a
						a: [ ]
						b: [   ]
					*/
          if (a2b2 === 2) {
            spans[l2] = b
            add = false
            break
          }
        }

        /*
					a1 > b1
				*/
        if (a1b1 === 1) {
          /*
						a2 === b2 -> overwrite a
							a:   [ ]
						  b: [   ]

						  a:   |
						  b: [ ]
					*/
          if (a2b2 === 0) {
            spans[l2] = b
            add = false
            break
          }

          /*
						a2 > b2
					*/
          if (a2b2 === 1) {
            /*
							a1 === b2 -> combine
								a:    [   ]
								b: [  ]

							a1 < b2 -> combine
								a:   [   ]
							  b: [   ]
						*/
            if ([0, 2].includes(a1b2)) {
              spans[l2] = [b1, a2]
              add = false
              continue
            }

            /*
						  a1 > b2:
							  a:        [   ]
							  b: [   ]

							if "add" is never set to false,
							this "b" span will be added
							to the span array 
						*/
            if (a1b2 === 1) {
              continue
            }
          }

          /*
						a2 < b2 -> overwrite a
					*/
          if (a2b2 === 2) {
            spans[l2] = b
            add = false
            continue
          }
        }

        /*
					a1 < b1
				*/
        if (a1b1 === 2) {
          /*
						a2 === b2 -> ignore b
						
						a: [   ]
						b:   [ ]

						a: [   |
						b:     |
					*/
          if (a2b2 === 0) {
            add = false
            break
          }

          /*
							a2 > b2 -> ignore b
								a: [     ]
								b:   [ ]
					*/
          if (a2b2 === 1) {
            add = false
            continue
          }

          // a2 < b2
          if (a2b2 === 2) {
            /*
							a2 === b1 -> combine
								a: [  ]
								b:    [   ]

							a2 > b1 -> combine
								a: [   ]
								b:   [    ]
						*/
            if ([0, 1].includes(a2b1)) {
              spans[l2] = [a1, b2]
              add = false
              break
            }

            /*
							a2 < b1 
								a: [    ]
								b:         [       ]

								if "add" is never set to false,
								this "b" span will be added
								to the span array 
						*/
            if (a2b1 === 2) {
              continue
            }
          }
        }
      }

      if (add) {
        spans.push(b)
        continue
      }
    }

    return {
      spans,
      issues
    }
  } catch (x) {
    console.debug(x)
  }

  return {
    spans: [],
    issues: []
  }
}

export function getTimeSpans({ availabilities, reservations = [] }) {
  try {
    const { spans, issues: sIssues } = determineSpans({ availabilities })

    /*
			assuming that all spans are sequential with no overlap...
		*/

    /*
			possible that reservation is outside of a span...

				e.g.

					s:   [||||||]    [||||||||]
					r:        [|||||]
		*/

    // f -> focus
    let oIssues = []
    const openings = cloneDeep(spans)
    for (let f1 = 0; f1 < reservations.length; f1++) {
      const reservation = reservations[f1]
      const [r1, r2] = reservation

      //
      // resolutionFound ->
      // 	broken or good
      //  before the completion of the loop
      //
      let resolutionFound = false
      for (let f2 = 0; f2 < openings.length; f2++) {
        const opening = openings[f2]
        const [o1, o2] = opening

        /*
					if r1 >= s1 and r2 <= s2 -> divide up the span

						example:
							span: 				[ "00:00:00", "24:00:00" ] 
							reservation: 	[ "04:00:00", "08:00:00" ]

						openings [
							[ "00:00:00", "04:00:00" ],
							[ "08:00:00", "24:00:00" ],
						]
				*/

        const o1r1 = compareTimes(o1, r1)
        const o1r2 = compareTimes(o1, r2)
        const o2r1 = compareTimes(o2, r1)
        const o2r2 = compareTimes(o2, r2)

        if (o1r1 === 0) {
          if (o2r2 === 0) {
            // ⚠ ⚠ ⚠
            // reservations and openings should both
            // be spans of time, not points
            //
            // o: |
            // r: |
            //
            resolutionFound = true
            break
          }
          if (o2r2 === 1) {
            if (compareTimes(r1, r2) === 0) {
              // ⚠ ⚠ ⚠
              // reservations should both
              // be spans of time, not points
              // o:  [       ]
              // r:  |
              //
              oIssues.push({
                reservation
              })
            }

            //
            // o: [    ]
            // r: [  ]
            //

            openings[f2] = [r2, o2]
            resolutionFound = true
            break
          }

          if (o2r2 === 2) {
            // ⚠ ⚠ ⚠
            // reservation should either be fully encapsulated
            // by openings or not bordering on an opening
            //
            //  o: [    ]
            //  r: [         ]
            //
            openings.splice(f2, 1)
            oIssues.push({
              reservation
            })
            resolutionFound = true
            break
          }
        }

        // o1 > r1
        if (o1r1 === 1) {
          /*
						o1 === r2
							o:    [  ]
							r: [  ]
					*/

          if (o2r2 === 0) {
            // ⚠ ⚠ ⚠
            // reservation should either be fully encapsulated
            // by openings or not bordering on an opening

            openings.splice(f2, 1)
            oIssues.push({
              reservation
            })
            resolutionFound = true
            break
          }

          // o2 > r2
          if (o2r2 === 1) {
            /*
							o1 === r2
								o:    [  ]
								r: [  ]
						*/
            if (o1r2 === 0) {
              // ⚠ ⚠ ⚠
              // reservation should either be fully encapsulated
              // by openings or not bordering on an opening
              continue
            }

            /*
							o1 > r2
								o:      [    ]
								r: [ ]
						*/

            if (o1r2 === 1) {
              // potentially problematic
              // reservation availability match not yet found
              continue
            }

            /*
							o1 < r2
								o:     [     ]
								r: [       ]
						*/
            if (o1r2 === 2) {
              openings[f2] = [r2, o2]
              oIssues.push({
                reservation
              })
              resolutionFound = true
              break
            }
          }

          /*
						o2 < r2
							o:    [   ]
							r: [         ]
					*/
          if (o2r2 === 2) {
            openings.splice(f2, 1)
            oIssues.push({
              reservation
            })
            resolutionFound = true
            break
          }
        }

        // o1 < r1
        if (o1r1 === 2) {
          if (o2r2 === 0) {
            openings[f2] = [o1, r1]
            resolutionFound = true
            break
          }

          /*
						o2 > r2
							o:   [      ] 
							r:    [    ]
					*/
          if (o2r2 === 1) {
            openings[f2] = [o1, r1]
            openings.splice(f2 + 1, 0, [r2, o2])
            resolutionFound = true
            break
          }

          /*
						o2 < r2
							o: [      ]
							r:    [       ]
					*/
          if (o2r2 === 2) {
            if (o2r1 === 0) {
              oIssues.push({
                reservation
              })
              resolutionFound = true
              break
            }

            if (o2r1 === 1) {
              openings[f2] = [o1, r1]
              oIssues.push({
                reservation
              })
              resolutionFound = true
              break
            }

            if (o2r2 === 2) {
              continue
            }
          }
        }

        //
        // possiby from:
        //		invalid time -> indeterminable comparison
        //

        oIssues.push({
          reservation
        })
        resolutionFound = true
        continue
      }

      if (!resolutionFound) {
        oIssues.push({
          reservation
        })
      }
    }

    return { spans, openings, oIssues, sIssues }
  } catch (x) {
    console.error(x)
  }

  return {
    spans: [],
    openings: []
  }
}

////////////////////
