<template>
  <b-field
    tabindex="0"
    :aria-label="ariaLabel"
    :label="label"
    style="display: inline-table; text-align: left"
  >
    <b-field :style="styles.monthField">
      <b-select placeholder="Month" :aria-label="ariaPrefix + ' month'" ref="month" v-model="month">
        <option value="01">January</option>
        <option value="02">February</option>
        <option value="03">March</option>
        <option value="04">April</option>
        <option value="05">May</option>
        <option value="06">June</option>
        <option value="07">July</option>
        <option value="08">August</option>
        <option value="09">September</option>
        <option value="10">October</option>
        <option value="11">November</option>
        <option value="12">December</option>
      </b-select>
    </b-field>

    <b-field :style="styles.yearField">
      <b-input
        v-model="year"
        placeholder="Year"
        :aria-label="ariaPrefix + ' year'"
        ref="year"
      ></b-input>
    </b-field>
  </b-field>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    ariaLabel: {
      type: String,
      default: 'date picker'
    },
    ariaPrefix: {
      type: String,
      default: ''
    }
  },
  data: () => {
    var field = {
      width: '70px',
      display: 'inline-block',
      paddingRight: '6px',
      textAlign: 'left'
    }

    return {
      month: null,
      year: '',
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      styles: {
        monthField: Object.assign({}, field, {
          width: '140px'
        }),
        yearField: field
      }
    }
  },
  watch: {},
  computed: {},
  methods: {
    getValue: function() {
      return {
        day: this.day,
        month: this.month,
        year: this.year
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

.button {
  margin-right: 6px;
  border-radius: 4px !important;
}

.datepicker {
}

@media screen and (min-width: $tablet) {
  .button {
    height: 40px;
    width: 40px;
  }
}
</style>
