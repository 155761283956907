import { amenityStore } from '@/services/Amenities/store'
import { notifyProblem } from '@/services/notify'

export const methods = {
  open({ amenityID }) {
    this.isOpen = true
    this.loading = false

    this.amenityID = amenityID
  },
  async add() {
    console.debug('in ReservabilityAdder add...')
    this.loading = true

    const startTime = this.$refs.fromTime.getValue()[24]
    const endTime = this.$refs.toTime.getValue()[24]
    const cleanupDurationInMinutes = this.formData.cleanupDurationInMinutes
    const dayOfWeek = this.formData.dayOfWeek
    const amenityID = this.amenityID

    const { successful, message } = await amenityStore.dispatch('addReservableTime', {
      json: {
        amenityID,
        dayOfWeek,
        startTime,
        endTime,
        cleanupDurationInMinutes
      }
    })
    if (!successful) {
      notifyProblem(message)
      this.loading = false
      return
    }

    this.callRefresh = true
    console.debug('calling refreshParent...')
    this.refreshParent()
    this.isOpen = false
  }
}
