<template>
  <Modal
    amenities-reservation-chooser
    :toggle.sync="isOpen"
    :loading="loading"
    :styles="{ _modal: { maxWidth: '1400px' } }"
    :checkBeforeClose="checkBeforeClose"
  >
    <template v-slot:header>
      <div>
        <div v-if="authUserType === 'HomeOwner' && showing !== 8">Reservation Chooser</div>
        <div v-if="authUserType === 'HomeOwner' && showing === 8">
          Amenity Fees - Due at Time of Reservation or Approval
        </div>
        <div v-if="authUserType === 'Account'">Details</div>
      </div>
    </template>

    <template v-slot:footer>
      <div style="max-width=900px">
        <div v-if="showing === 6" :style="{ display: 'flex', justifyContent: 'space-between' }">
          <b-button @click="show(4)">Back</b-button>
          <b-button
            type="is-danger"
            v-if="authUserType === 'HomeOwner'"
            rounded
            @click="cancelReservation()"
            >Cancel Reservation</b-button
          >
        </div>

        <div v-if="showing === 5" :style="{ display: 'flex', justifyContent: 'space-between' }">
          <b-button @click="show(4)">Back</b-button>
          <b-button @click="updateReservableTime" class="button is-primary is-small"
            >Update</b-button
          >
        </div>

        <div v-if="showing === 4" :style="{ display: 'flex', justifyContent: 'space-between' }">
          <b-button @click="back">Back</b-button>

          <b-tooltip
            label="Please select the new time span."
            v-if="allowUpdate === 0"
            position="is-left"
          >
            <b-button class="button is-primary is-small" disabled>Update</b-button>
          </b-tooltip>

          <b-tooltip
            label="The end time needs to be after the start time."
            v-if="allowUpdate === 2"
            position="is-left"
          >
            <b-button class="button is-primary is-small" disabled>Update</b-button>
          </b-tooltip>

          <b-button
            v-if="allowUpdate === 1"
            @click="confirmUpdateReservableTime"
            class="button is-primary is-small"
            >Update</b-button
          >
        </div>

        <div v-if="showing === 3" :style="{ display: 'flex', justifyContent: 'end' }">
          <b-button @click="closeModal">Close</b-button>
        </div>

        <div v-if="showing === 2" :style="{ display: 'flex', justifyContent: 'space-between' }">
          <b-button @click="back">Back</b-button>
          <b-button @click="deleteReservableTime" type="is-danger">Delete</b-button>
        </div>

        <div
          v-if="showing === 1 && authUserType === 'HomeOwner'"
          :style="{ display: 'flex', justifyContent: 'end' }"
        >
          <b-button
            v-if="
              authUserType === 'HomeOwner' && _get(amenityData, 'requiresApproval', null) !== true
            "
            @click="reserve"
            type="is-primary"
            >Reserve</b-button
          >

          <b-button
            v-if="
              authUserType === 'HomeOwner' &&
                _get(amenityData, 'requiresApproval', null) === true &&
                amenityFeeTotal <= 0
            "
            @click="requestReservation"
            type="is-primary"
            >Request to Reserve</b-button
          >
          <span class="pl-3" v-if="authUserType === 'HomeOwner'">&nbsp;</span>
          <b-button
            v-if="authUserType === 'HomeOwner' && amenityFeeTotal > 0"
            @click="requestReservation(true)"
            type="is-primary"
            >Continue to Fees</b-button
          >

          <b-tooltip
            label="Only home owners may reserve amenities."
            position="is-left"
            v-if="authUserType === 'Account'"
          >
            <b-button type="is-primary" disabled>Reserve</b-button>
          </b-tooltip>
        </div>

        <div
          v-if="showing === 8 && authUserType === 'HomeOwner'"
          class="scrollbar"
          style="max-height: 615px !important;"
        >
          <div
            :style="{
              display: 'flex',
              marginBottom: '12px',
              ...(breakpoint === 'desktop'
                ? {}
                : {
                    flexDirection: 'column'
                  })
            }"
          >
            <div class="card" :style="{ padding: '12px', flexGrow: 2 }">
              <div v-for="(fee, index) in amenityFees" :key="index">
                <div class="columns" style="color:black">
                  <div class="column is-10 font-bold">
                    {{ fee.name || '' }}
                    {{ `${fee.cancelRefundable === true ? ' (Refundable)' : ' (Non-Refundable)'}` }}
                  </div>
                  <div class="column is-2 text-right">{{ fee.amount | currency }}</div>
                </div>
                <div class="columns pl-5" style="color:black">
                  <div class="column is-12">{{ fee.description || '' }}</div>
                </div>
              </div>
              <div class="columns" style="color:black">
                <div class="column is-10 font-bold">
                  Convenience Fee (Non-Refundable)
                </div>
                <div class="column is-2 text-right font-larger2">
                  {{ convenienceFee | currency }}
                </div>
              </div>
              <div class="columns" style="color:black">
                <div class="column is-10 font-bold text-right">Total Due Today:</div>
                <div class="column is-2 font-bold text-right">
                  {{ (amenityFeeTotal + convenienceFee) | currency }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
              <transition name="fade" mode="out-in" appear>
                <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
                  <div class="columns" style="color:black">
                    <div class="column is-12 font-bold">Payment Method Type</div>
                  </div>
                  <div style="width:500px;">
                    <valid-select
                      placeholder="Payment Method Type"
                      vid="amenityPaymentMethodType"
                      v-model="amenityPaymentMethodType"
                      aria-required="The payment method type is required"
                      rules="required"
                    >
                      <option
                        v-for="option in amenityPaymentMethodTypes"
                        :value="option.value"
                        :key="option.value"
                        >{{ option.label }}</option
                      >
                    </valid-select>
                  </div>

                  <div
                    v-if="amenityPaymentMethodType && amenityPaymentMethodType == 'stored'"
                    class="pt-5"
                    style="width:500px;"
                  >
                    <label class="label"
                      >Payment Method<Info
                        title="Name"
                        information="Select the stored payment that you want to use to make this payment."
                    /></label>
                    <valid-select
                      placeholder="Payment Method"
                      vid="ownerPaymentMethodID"
                      v-model="ownerPaymentMethodID"
                      aria-required="The payment method is required"
                      rules="required"
                    >
                      <option
                        v-for="option in paymentMethods"
                        :value="option.value"
                        :key="option.value"
                        >{{ option.label }}</option
                      >
                    </valid-select>
                  </div>
                  <div
                    v-if="amenityPaymentMethodType && amenityPaymentMethodType == 'creditcard'"
                    class="pt-1"
                  >
                    <div class="columns">
                      <div class="column is-5">
                        <label class="label"
                          >Credit Card Number<Info
                            title="Name"
                            information="The credit card number."
                        /></label>
                        <b-input
                          id="cardnumber"
                          v-model="creditCardNumber"
                          name="creditCardNumber"
                          type="text"
                          vid="creditCardNumber"
                          placeholder="Credit Card Number"
                          rules="required|max:100"
                        >
                        </b-input>
                      </div>
                      <div class="column is-5">
                        <label class="label"
                          >Card Holder Name<Info title="Name" information="The card holder name."
                        /></label>
                        <valid-input
                          v-model="cardHolderName"
                          name="cardHolderName"
                          type="text"
                          vid="cardHolderName"
                          placeholder="Card Holder Name"
                          rules="required|max:255"
                        />
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column is-5 pt-1">
                        <label class="label"
                          >Expiration Date<Info
                            title="Expiration Date"
                            information="The date that the credit card expires."
                        /></label>
                        <b-field>
                          <DateMonthPicker required ref="cardExpirationDateComponent" />
                        </b-field>
                      </div>
                      <div class="column is-5 pt-1">
                        <label class="label"
                          >CVC<Info
                            title="CVC"
                            information="The credit card verification code. This code is the final three digits of the number printed on the signature strip on the reverse side of your card."
                        /></label>

                        <valid-input
                          v-model="cvc"
                          name="cvc"
                          type="text"
                          vid="cvc"
                          placeholder="Card Verification Code"
                          rules="required|max:5"
                        />
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column is-5 pt-1">
                        <div class="column is-12 pt-2">
                          <label class="label"
                            >Postal Code<Info
                              title="Postal Code"
                              information="The postal code of the credit card holder's mailing address."
                          /></label>
                        </div>
                        <valid-input
                          v-model="zipCode"
                          name="zipCode"
                          type="text"
                          vid="zipCode"
                          placeholder="Zip Code"
                          maxlength="5"
                          rules="required|max:5"
                        />
                      </div>
                    </div>
                    <div class="columns" v-show="false">
                      <div class="column is-5 pl-3 pt-5">
                        <b-checkbox class="has-left-text" v-model="addCreditCardPaymentMethod"
                          >Save this Payment Method?</b-checkbox
                        >
                      </div>
                      <div v-if="addCreditCardPaymentMethod === true" class="column is-5">
                        <label class="label"
                          >Account Alias<Info
                            title="Name"
                            information="Assign a name to your account. This can be any name that you want to assign."
                        /></label>
                        <valid-input
                          v-model="accountAliasCreditCard"
                          name="accountAliasCreditCard"
                          type="text"
                          vid="accountAliasCreditCard"
                          placeholder="Account Alias"
                          rules="max:255"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="pb-5 pt-5 primary">
                    <span class="is-left pr-3"><b-button @click="back">Back</b-button></span>
                    <button type="submit" :disabled="loading" class="button is-primary pl-3">
                      Send Payment
                    </button>
                  </div>
                  <fieldset>
                    <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                      <div class="field">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </fieldset>
                </form>
              </transition>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </template>

    <div v-if="showing === 1">
      <h4>{{ mDate.monthStr }} {{ mDate.dayOfMonth }}, {{ mDate.year }}</h4>

      <div
        :style="{
          display: 'flex',
          marginBottom: '12px',
          ...(breakpoint === 'desktop'
            ? {}
            : {
                flexDirection: 'column'
              })
        }"
      >
        <div class="card" :style="{ padding: '12px', flexGrow: 3 }">
          <h5 :style="{ margin: 0, padding: 0 }">Current Availability</h5>
          <div v-for="(opening, index) in openings" :key="index">
            {{ toMeridian(opening[0]) }} to {{ toMeridian(opening[1]) }}
          </div>
          <div v-if="openings.length === 0">None</div>
        </div>

        <div
          v-show="false"
          class="card"
          :style="{
            padding: '12px',
            flexGrow: 2,
            ...(breakpoint === 'desktop'
              ? {
                  marginLeft: '12px'
                }
              : {
                  marginTop: '12px'
                })
          }"
        >
          <h5 :style="{ margin: 0, padding: 0 }">Original Reservable Times</h5>
          <div
            class="card"
            v-for="(reservableTime, index) in reservableTimes"
            :key="index"
            :style="{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '8px',
              margin: '4px 0'
            }"
          >
            <div>{{ toMeridian(reservableTime[0]) }} to {{ toMeridian(reservableTime[1]) }}</div>
            <div v-if="authUserType === 'Account'">
              <b-button
                @click="confirmEditReservableTime({ reservableTime })"
                class="button is-info is-light is-small is-outline is-rounded"
                >Edit</b-button
              >
              <b-button
                @click="confirmDeleteReservableTime({ reservableTime })"
                :style="{ marginLeft: '8px' }"
                class="button is-info is-light is-small is-outline is-rounded"
                >Delete</b-button
              >
            </div>
          </div>
          <div v-if="reservableTimes.length === 0">None</div>
        </div>
      </div>

      <div :style="{ display: 'flex' }">
        <div class="card" :style="{ padding: '12px', flexGrow: 2 }">
          <h5 :style="{ margin: 0, padding: 0 }">Other Reservations</h5>

          <div
            v-for="(reservation, index) in reservations"
            :key="index"
            class="card"
            :style="{
              display: 'flex',
              justifyContent: 'space-between',

              marginTop: '6px',
              padding: '8px',
              alignItems: 'center'
            }"
          >
            <div :style="{ display: 'flex', alignItems: 'center' }">
              <b-tag size="is-medium"
                >{{ toMeridian(reservation[0]) }} to {{ toMeridian(reservation[1]) }}</b-tag
              >
              <span :style="{ padding: '0 10px' }">for</span>
              <b-tag size="is-medium" :style="{ paddingLeft: '10px' }">{{ reservation[2] }}</b-tag>

              <StatusTile
                :style="{ marginLeft: '12px' }"
                v-if="
                  _get(amenityData, ['requiresApproval'], false) &&
                    !reservationIsApproved({ reservation: _get(reservation, [3], null) })
                "
                text="Pending Approval"
                :background="1"
              />

              <StatusTile
                :style="{ marginLeft: '12px' }"
                v-if="
                  _get(amenityData, ['requiresApproval'], false) &&
                    reservationIsApproved({ reservation: _get(reservation, [3], null) })
                "
                text="Approved"
                :background="2"
              />
            </div>

            <b-button
              type="is-primary"
              v-if="
                authUserType === 'Account' &&
                  _get(amenityData, 'requiresApproval', null) === true &&
                  !reservationIsApproved({ reservation: _get(reservation, 3, null) })
              "
              rounded
              @click="approveReservationRequest({ reservation })"
              >Approve Request</b-button
            >

            <b-button
              type="is-danger"
              v-if="
                authUserType === 'HomeOwner' &&
                  typeof ownerID === 'number' &&
                  ownerID === _get(reservation, [3, 'reservationOwnerID'], null)
              "
              rounded
              @click="confirmCancelReservation({ reservation })"
              >Cancel Reservation</b-button
            >
          </div>

          <div v-if="reservations.length === 0">None</div>
        </div>
      </div>
      <div
        v-if="authUserType === 'HomeOwner'"
        class="card"
        :style="{ padding: '12px', marginTop: '12px' }"
      >
        <h4 v-if="_get(amenityData, 'requiresApproval', null) === true">New Reservation Request</h4>
        <h4 v-else>New Reservation</h4>

        <b-field label="From" />
        <TimePicker ref="fromTime" />

        <br />

        <b-field label="To" />
        <TimePicker ref="toTime" />
      </div>
    </div>

    <div v-if="showing === 2">
      <div>
        <div>
          There are <b>{{ toDelete.reliantReservations.length }}</b> reservation{{
            toDelete.reliantReservations.length === 1 ? '' : 's'
          }}
          or reservation request{{ toDelete.reliantReservations.length === 1 ? '' : 's' }} that will
          be cancelled if this reservable time is deleted.
        </div>

        <div v-for="(rr, i) in toDelete.reliantReservations" :key="i" :style="{ margin: '4px 0' }">
          <b-tag>{{ rr[0] }}</b-tag> to <b-tag>{{ rr[1] }}</b-tag> for <b-tag>{{ rr[2] }}</b-tag>
        </div>
      </div>

      <br />

      <div>Are you sure you want to delete this reservable time block?</div>
      <div>
        <span
          >Every <b>{{ toDelete.reservableTimeDayOfWeek }}</b> from
          <b>{{ toDelete.startTime }}</b> to <b>{{ toDelete.endTime }}</b></span
        >
      </div>
    </div>

    <div v-if="showing === 3">
      <p>This reservable time slot has been deleted.</p>
      <p>
        Every <b>{{ toDelete.reservableTimeDayOfWeek }}</b> from <b>{{ toDelete.startTime }}</b> to
        <b>{{ toDelete.endTime }}</b>
      </p>

      <br />

      <p v-if="deleted.successes >= 1">
        <b>{{ deleted.successes }}</b> reservations have been cancelled.
      </p>
      <p v-if="deleted.problems.length >= 1">
        <b>{{ deleted.problems.length }}</b> reservations may not have been cancelled successfully.
      </p>
    </div>

    <div v-if="showing === 4">
      <h4>Reservable Time Update</h4>

      <div class="card" :style="{ padding: '16px' }">
        <h5 :style="{ margin: 0 }">Currently</h5>
        <span
          >Every <b>{{ toUpdate.dayOfWeek }}</b> from <b>{{ toMeridian(toUpdate.startTime) }}</b> to
          <b>{{ toMeridian(toUpdate.endTime) }}</b></span
        >
      </div>

      <br />

      <div
        :style="{
          display: 'flex',
          ...(breakpoint === 'desktop'
            ? {}
            : {
                flexDirection: 'column'
              })
        }"
      >
        <div class="card" :style="{ padding: '16px' }">
          <h5 :style="{ margin: 0 }">Update To</h5>

          <b-field label="From" />
          <TimePicker
            ref="editFromTime"
            :onUpdate="updaterOnUpdate"
            v-model="toUpdate.proposal.startTime"
          />

          <br />

          <b-field label="To" />
          <TimePicker
            ref="editToTime"
            :onUpdate="updaterOnUpdate"
            v-model="toUpdate.proposal.endTime"
          />
        </div>

        <div
          class="card"
          :style="{
            padding: '16px',
            border: `3px solid` + theme.color[4],
            ...(breakpoint === 'desktop'
              ? {}
              : {
                  marginTop: '12px'
                })
          }"
        >
          <h5 :style="{ margin: 0 }">Affected Reservations</h5>

          <p>
            <b>{{ affectedReservations.length }}</b> reservation{{
              affectedReservations.length === 1 ? '' : 's'
            }}
            or or reservation request{{ affectedReservations.length === 1 ? '' : 's' }} will be
            cancelled if the reservable time is updated to the selected span.
          </p>

          <div :style="{ overflowY: 'scroll', height: '74%' }">
            <div v-for="(r, i) in affectedReservations" :key="i" :style="{ margin: '6px 0' }">
              <p>
                <b-tag>{{ r[0] }}</b-tag> to <b-tag>{{ r[1] }}</b-tag> for <b-tag>{{ r[2] }}</b-tag>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showing === 5">
      <h4>Reservable Time Update Confirmation</h4>
      <p>Please confirm that you would like to update the reservable time</p>
      <div>
        <b-tag :style="{ width: '50px', marginRight: '8px' }">From</b-tag>
        <span
          >Every <b>{{ toUpdate.dayOfWeek }}</b> from <b>{{ toMeridian(toUpdate.startTime) }}</b> to
          <b>{{ toMeridian(toUpdate.endTime) }}</b></span
        >
      </div>
      <div :style="{ marginTop: '8px' }">
        <b-tag :style="{ width: '50px', marginRight: '8px' }">To</b-tag>
        <span
          >Every <b>{{ toUpdate.proposal.dayOfWeek }}</b> from
          <b>{{ toMeridian(toUpdate.proposal.startTime) }}</b> to
          <b>{{ toMeridian(toUpdate.proposal.endTime) }}</b></span
        >
      </div>

      <br />

      <p>
        <b>{{ affectedReservations.length }}</b> reservation{{
          affectedReservations.length === 1 ? '' : 's'
        }}
        or reservation request{{ affectedReservations.length === 1 ? '' : 's' }}
        will be cancelled if the reservable time is updated.
      </p>

      <div v-for="(r, i) in affectedReservations" :key="i" :style="{ margin: '6px 0' }">
        <p>
          <b-tag>{{ r[0] }}</b-tag> to <b-tag>{{ r[1] }}</b-tag> for <b-tag>{{ r[2] }}</b-tag>
        </p>
      </div>
    </div>

    <div v-if="showing === 6">
      <h4>Reservable Cancellation Confirmation</h4>
      <p>
        To confirm that you would like to cancel the reservation, please press the cancel button.
      </p>
      <div
        v-if="
          reservationToCancel !== undefined &&
            reservationToCancel &&
            reservationIsApproved({ reservation: reservationToCancel })
        "
      >
        <p>Refundable Amount: {{ `${reservationToCancel.chargeAmount || 0}` | currency }}</p>
        <p>
          Service Fee Amount (Non-Refundable):
          {{ `${reservationToCancel.nonRefundableAmount || 0}` | currency }}
        </p>
        <p>
          Convenience Fee Amount (Non-Refundable):
          {{ `${convenienceFee || 0}` | currency }}
        </p>
      </div>
    </div>
  </Modal>
</template>

<script>
/*
  possibilities:
    Checkboxes instead of select

    Every:
      [ ] Sunday
      [ ] Monday
      ...
      [ ] Saturday 
*/

import Modal from '@/components/Modal'
import TimePicker from '@/components/inputs/TimePickerFifteen'
import StatusTile from '@/components/tiles/Status'
import ValidSelect from '@/components/inputs/ValidSelect'
import ValidInput from '@/components/inputs/ValidInput'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { computed } from './keys/computed'
import { windowStore } from '@/utilities/window/store'
import Info from '@/components/icons/Info'
import DateMonthPicker from '@/components/inputs/DateMonthYearPicker'
import Cleave from 'cleave.js'

const cleave = {
  name: 'cleave',
  bind(el, binding) {
    const input = el.querySelector('input')
    input._vCleave = new Cleave(input, binding.value)
  },
  unbind(el) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
  }
}

export default {
  directives: { cleave },
  props: {
    refreshParent: Function
  },
  components: {
    Modal,
    StatusTile,
    TimePicker,
    ValidSelect,
    ValidInput,
    Info,
    DateMonthPicker,
    ValidationObserver,
    ValidationProvider
  },

  watch: {
    async amenityPaymentMethodType() {
      await this.loadServiceFees()
    },
    async ownerPaymentMethodID() {
      await this.loadServiceFees()
    }
  },

  computed,
  async mounted() {
    await this.loadPaymentMethodTypes()
    await this.loadPaymentMethods()

    this.unsubWindowStore = windowStore.subscribe((mutation, state) => {
      const mType = mutation.type

      if (mType === 'breakPointChange') {
        try {
          this.breakpoint = state.breakpoint
        } catch (error) {
          console.debug('error=' + error)
        }
      }
    })

    try {
      this.breakpoint = windowStore.state.breakpoint
    } catch (error) {
      console.debug('error=' + error)
    }

    await this.loadServiceFees()
  },
  beforeDestroy() {
    this.unsubWindowStore()
  },
  data,
  methods
}
</script>
<style lang="scss" scope>
.scrollbar {
  --max-lines: 3;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: auto;
  padding-right: 1rem;
}
</style>
