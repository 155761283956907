/*
  import { findMultipleReservations } from '@/services/Amenities/AmenityReservation/sequences/findMultipleReservations';

  const { reservations, problems } = await findMultipleReservations ({
    reservationIDs: [
      amenityReservationID,
      amenityReservationID,
      amenityReservationID
    ]
  });
  if (problems.length >= 1) {

  }
*/

import { getAmenityReservation } from '../Get'

export async function findMultipleReservations({ reservationIDs }) {
  const results = await Promise.all(
    reservationIDs.map(amenityReservationID => {
      return getAmenityReservation({
        amenityReservationID
      })
    })
  )

  const problems = []
  const retrievals = []

  for (let a = 0; a < results.length; a++) {
    const result = results[a]
    const { successful } = result

    if (successful === true) {
      retrievals.push(result.reservation)
    } else {
      problems.push(result)
    }
  }

  return {
    reservations: retrievals,
    problems
  }
}
