/*
	import { epicGateway } from '@/services/EpicGateway/store';
*/

import Vue from 'vue'
import Vuex from 'vuex'
import kms from '@/services/kms'
import { notifyProblem, notifyError } from '@/services/notify'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  // Register Token for a credit card
  async addOwnerPaymentMethodCreditCard(
    { dispatch },
    {
      cardHolderName,
      expMonth,
      expYear,
      ownerUserID,
      paymentMethodType,
      lastFourDigits,
      token,
      tokenType,
      accountAlias,
      done
    }
  ) {
    if (isDebug == true) console.debug('...in addOwnerPaymentMethodCreditCard')
    try {
      let params = null

      if (paymentMethodType == 'Credit Card') {
        params = {
          method: 'credit_card',
          cardHolderName: cardHolderName,
          expMonth: expMonth,
          expYear: expYear,
          ownerUserID: ownerUserID,
          paymentMethodType: paymentMethodType,
          lastFourDigits: lastFourDigits,
          token,
          tokenType,
          accountAlias
        }
      }

      console.debug('AddOwnerPaymentMethodCreditCard params=' + JSON.stringify(params))

      const results = await kms.post(`/Roster/Owner/AddOwnerPaymentMethodCreditCard`, params)

      if (isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results) {
        done({ details: results })
      } else {
        notifyError('There was a problem adding the credit card payment method.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this credit card payment method.`)
      console.error(exception)
    }
  },

  async addDeferredOwnerPaymentMethodCreditCard({ dispatch }, { params, done }) {
    if (isDebug == true) console.debug('...in addDeferredOwnerPaymentMethodCreditCard')
    try {
      console.debug('addDeferredOwnerPaymentMethodCreditCard params=' + JSON.stringify(params))

      const results = await kms.post(`/Roster/Owner/AddOwnerPaymentMethodCreditCard`, params)

      if (isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results) {
        done({ details: results })
      } else {
        notifyError('There was a problem adding the deferred credit card payment method.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this deferred credit card payment method.`)
      console.error(exception)
    }
  },

  async addOwnerPaymentMethodBankAccount(
    { dispatch },
    {
      accountType,
      accountHolderName,
      ownerUserID,
      paymentMethodType,
      lastFourDigits,
      token,
      tokenType,
      accountAlias,
      done
    }
  ) {
    if (isDebug == true) console.debug('...in addOwnerPaymentMethodBankAccount')
    try {
      let params = {
        method: 'echeck',
        accountType: accountType,
        accountHolderName: accountHolderName,
        ownerUserID: ownerUserID,
        paymentMethodType: paymentMethodType,
        lastFourDigits: lastFourDigits,
        token: token,
        tokenType: tokenType,
        accountAlias
      }

      const results = await kms.post(`/Roster/Owner/AddOwnerPaymentMethodBankAccount`, params)

      if (isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results) {
        done({ details: results })
      } else {
        notifyError('There was a problem adding the bank account payment method.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this bank account payment method.`)
      console.error(exception)
    }
  },

  // Register Token for a credit card
  async registerTokenExtCreditCardMethod(
    { dispatch },
    { cardNumber, cardHolderName, expMonth, expYear, cvc, done }
  ) {
    if (isDebug == true) console.debug('...in registerTokenExtCreditCardMethod')
    try {
      const params = {
        method: 'credit_card',
        creditCard: {
          cardNumber: cardNumber,
          cardHolderName: cardHolderName,
          expMonth: expMonth,
          expYear: expYear,
          cvv: cvc
        }
      }
      const results = await kms.post(`/FortisEpicGateway/EpicGateway/RegisterTokenExt`, params)

      if (isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results) {
        done({ details: results })
      } else {
        notifyError('There was a problem adding the credit card.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this credit card.`)
      console.error(exception)
    }
  },

  // Register Token for a bank account
  async registerTokenExtBankAccountMethod(
    { dispatch },
    { accountType, routingNumber, accountNumber, accountHolderName, done }
  ) {
    if (isDebug == true) console.debug('...in registerTokenExtBankAccountMethod')

    const params = {
      method: 'echeck',
      bankAccount: {
        accountType: accountType,
        routingNumber: routingNumber,
        accountNumber: accountNumber,
        accountHolderName: accountHolderName
      }
    }

    try {
      const results = await kms.post(`/Roster/Owner/AddOwnerPaymentMethodBankAccount`, params)

      if (isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results) {
        done({ details: results })
      } else {
        notifyError('There was a problem adding the bank account.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this bank account.`)
      console.error(exception)
    }
  },

  //Make Payment Type List
  async getMakePaymentTypeList({}) {
    try {
      let makePaymentTypes = [
        { value: 'creditcard', label: 'One-time Credit Card payment' },
        { value: 'bankaccount', label: 'One-time Bank Account payment' },
        { value: 'stored', label: 'From your stored payment methods' }
      ]

      if (isDebug == true) console.debug('makePaymentTypes=' + JSON.stringify(makePaymentTypes))

      makePaymentTypes.unshift({ value: '', label: '<-Select Make Payment Type->' })

      return {
        list: makePaymentTypes
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the make payment type list.`)
      console.error(exception)
    }

    return {
      list: []
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const epicGateway = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
