// /*
//   import { amenityStore } from '@/services/Amenities/Amenity/store'
//})
// */

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

/* eslint-disable no-empty-pattern */
const actions = {
  // Update Amenity
  async updateAmenity({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/Amenities/Amenity/UpdateMinimal`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.amenityID > 0) {
        notifyMessage(`Successfully updated this amenity.`)
        done({ detail: results })
      } else {
        notifyError('There was a problem updating this amenity.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for this amenity.`)
      console.error(exception)
    }
  },

  //Make Payment Type List
  async getAmenityFeePaymentMethodTypes({}) {
    try {
      let amenityFeePaymentMethodTypes = [
        { value: 'creditcard', label: 'Credit Card (New)' },
        { value: 'stored', label: 'Credit Card (Saved)' }
      ]

      console.debug('amenityFeePaymentMethodTypes=' + JSON.stringify(amenityFeePaymentMethodTypes))

      return {
        list: amenityFeePaymentMethodTypes
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the amenity fee payment type list.`)
      console.error(exception)
    }

    return {
      list: []
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const amenityStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
