/*
	import { compareTimes } from "@/utilities/time/compare"

	const a = "22:00:00";
	const b = "09:00:00";

	const comparison = compareTimes (a, b);

	// results:
	//   0 -> same
	//   1 -> a (1st param) is later
	//   2 -> b (2nd param) is later
	//
	//   3 -> indeterminable
	//   4 -> indeterminable
	//   5 -> indeterminable
	//   6 -> indeterminable
*/

/*
	// a >= b
	if ([ 0, 1 ].includes (compareTimes (a, b))) {
		
	}

	// a > b
	if ([ 1 ].includes (compareTimes (a, b))) {
		
	}

	// b >= a
	if ([ 0, 2 ].includes (compareTimes (a, b))) {
	
	}

	// b > a
	if ([ 2 ].includes (compareTimes (a, b))) {
	
	}
*/

/*
	00:00:00 to 24:00:00
*/

import { timeIsInvalid } from '@/utilities/time/isInvalid'

export function compareTimes(a, b) {
  try {
    const aInvalid = timeIsInvalid(a)
    if (aInvalid) {
      console.error(aInvalid)
      return 3
    }

    const bInvalid = timeIsInvalid(b)
    if (bInvalid) {
      console.error(bInvalid)
      return 4
    }

    // p -> parsed
    const p = {
      a: a.split(':').map(e => parseInt(e)),
      b: b.split(':').map(e => parseInt(e))
    }

    // if a and b are both either 24:00:00 or 00:00:00
    if ([a, b].filter(t => ['24:00:00', '00:00:00'].includes(t)).length === 2) {
      return 0
    }

    for (let c = 0; c <= 2; c++) {
      if (p.a[c] > p.b[c]) {
        return 1
      }
      if (p.b[c] > p.a[c]) {
        return 2
      }
    }

    if (a === b) {
      return 0
    }

    return 5
  } catch (x) {
    // x -> exception
    console.error(x)
  }

  return 6
}
