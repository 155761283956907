import _get from 'lodash/get'
import moment from 'moment'
import { getAmenity } from '@/services/Amenities/Amenity/Get'
import { notifyProblem } from '@/services/notify'
import { allDatesInMonth } from '@/utilities/Date/allDatesInMonth'
import { wkDaysNums, monthNums, monthStrs } from '@/utilities/Date/constants'
import { timeIsInvalid } from '@/utilities/time/isInvalid'
import { getTimeSpans } from '@/utilities/time/getSpans'
import { toMeridian } from '@/utilities/time/toMeridian'
import { filterReservableTimes } from '@/utilities/amenities/filterReservableTimes'
import { parseReservations } from '@/utilities/amenities/parseReservations'
import { reservationIsApproved } from '@/utilities/amenities/reservationIsApproved'
import { calcOwnerEventTotals } from '@/utilities/amenities/calculate/ownerEventTotals'

export const methods = {
  async calendarUpdate() {
    const { year, month } = this.$refs.calendar.getInfo()
    this.shown.year = year
    this.shown.month = monthNums[month]

    await this.show()
  },

  dayTapped({ dayOfMonth, month, year }) {
    this.dayTappedDay = dayOfMonth
    this.dayTappedMonth = month
    this.dayTappedYear = year

    this.$refs.reservationChooser.open({
      amenityData: this.amenityData,
      dayOfMonth,
      month,
      year
    })
  },

  calculateYourReservationCounts() {
    const amenityData = _get(this, ['amenityData'], [])
    const ownerID = this.currentOwnerId

    const { totals: your } = calcOwnerEventTotals({
      amenityData,
      ownerID
    })

    this.total.yourFutureReservationRequests = your.future.requests.count
    this.total.yourPastReservationRequests = your.past.requests.count

    this.total.yourFutureReservations = your.future.reservations.count
    this.total.yourPastReservations = your.past.reservations.count
  },
  calculateTotals() {
    const amenityReservations = _get(this, ['amenityData', 'amenityReservations'], [])
    const requiresApproval = _get(this, ['amenityData', 'requiresApproval'], null)

    const now = moment()

    // const { year, month } = this.$refs.calendar.getInfo ();
    // const { dates: datesInMonth } = allDatesInMonth ({
    // 	year,
    // 	month
    // })

    const { reservations: dates } = parseReservations({
      amenityReservations
    })

    const total = {
      future: {
        reservations: {
          count: 0,
          list: []
        },
        requests: {
          count: 0,
          list: []
        }
      },
      past: {
        reservations: {
          count: 0,
          list: {}
        },
        requests: {
          count: 0,
          list: {}
        }
      }
    }

    for (let key in dates) {
      const thisDate = moment(key)
      const isPast = thisDate.isBefore(now)
      const reservations = dates[key]

      for (let a = 0; a < reservations.length; a++) {
        const reservation = _get(reservations, [a, 2], null)
        const isApproved = reservationIsApproved({
          reservation
        })

        if (isPast) {
          if (requiresApproval === true) {
            if (isApproved) {
              total.past.reservations.count += 1
            } else {
              total.past.requests.count += 1
            }
          } else {
            total.past.reservations.count += 1
          }
        } else {
          if (requiresApproval === true) {
            if (isApproved) {
              total.future.reservations.count += 1
            } else {
              total.future.requests.count += 1
            }
          } else {
            total.future.reservations.count += 1
          }
        }
      }
    }

    this.total.futureReservations = total.future.reservations.count
    this.total.pastReservations = total.past.reservations.count

    this.total.futureReservationRequests = total.future.requests.count
    this.total.pastReservationRequests = total.past.requests.count
  },

  refreshParent(from) {
    console.debug('refreshing parent....')
    this.refresh().then(() => {
      if (from !== undefined && from && from === 'chooser') {
        this.$refs.reservationChooser.open({
          amenityData: this.amenityData,
          dayOfMonth: this.dayTappedDay,
          month: this.dayTappedMonth,
          year: this.dayTappedYear
        })
      }
      //else if(from !== undefined && from && from === 'cancelandrefund')
      //{
      //
      //}
      else {
        this.dayTappedDay = null
        this.dayTappedMonth = null
        this.dayTappedYear = null
      }
    })
  },

  initDatePickers() {
    const { year, month } = this.$refs.calendar.getInfo()

    this.shown.month = monthNums[month]
    this.shown.year = year
  },
  async move() {
    this.$refs.calendar.move({
      month: parseInt(monthStrs[this.shown.month]),
      year: parseInt(this.shown.year)
    })
  },
  async openAddModal() {
    const amenityID = this._testAmenityID || this.$route.params.id

    this.$refs.reservabilityAdder.open({
      amenityID
    })
  },

  async refresh() {
    console.debug('refresh..............................')
    this.loading = true

    this.initDatePickers()

    const amenityID = this._testAmenityID || this.$route.params.id
    const { successful, message, data } = await getAmenity({
      amenityID
    })
    if (!successful) {
      this.loading = false
      notifyProblem(message)
      return
    }

    this.amenityData = data
    this.reservationDurationLimitInMinutes = _get(data, 'reservationDurationLimitInMinutes', '')
    this.requiresApproval = _get(data, 'requiresApproval', null)
    this.amenityReservableTimes = _get(data, 'amenityReservableTimes', [])

    this.name = _get(data, 'name', '')
    this.show()

    const { reservableTimes } = filterReservableTimes({
      amenityReservableTimes: this.amenityReservableTimes
    })

    this.reservableTimes = reservableTimes

    if (Array.isArray(this.amenityReservableTimes)) {
      this.total.reservableTimes = this.amenityReservableTimes.length
    } else {
      this.total.reservableTimes = 0
    }

    const amenityReservations = _get(this, ['amenityData', 'amenityReservations'], [])
    this.total.reservations = amenityReservations.length

    this.calculateYourReservationCounts()
    this.calculateTotals()

    this.found = true
    this.loading = false
  },

  ////////////////////////////////////////////////////////////////////
  ////
  ////	The events that are displayed on the calendar
  ////
  ////
  async showYourReservations() {
    // const { dates, datesInMonth } = this.calculateYourReservationCounts ();

    const amenityReservations = _get(this, ['amenityData', 'amenityReservations'], [])
    const ownerID = this.currentOwnerId
    const requiresApproval = _get(this, ['amenityData', 'requiresApproval'], null)
    const { year, month } = this.$refs.calendar.getInfo()

    const { dates: datesInMonth } = allDatesInMonth({
      year,
      month
    })

    // filters by ownerID
    const { reservations: dates } = parseReservations({
      amenityReservations,
      ownerID
    })

    const now = moment()

    const events = []
    const last = datesInMonth.length
    for (let a = 0; a < last; a++) {
      const date = datesInMonth[a]
      const m = moment(date)
      // const dayOfWeek = m.day() + 1

      const isPast = m.isBefore(now)

      const _date = moment(date).format('YYYY-MM-DD')
      let reservations = dates[_date]

      if (!isPast) {
        if (Array.isArray(reservations) && reservations.length >= 1) {
          for (let a = 0; a < reservations.length; a++) {
            const reservation = _get(reservations, [a, 2], null)
            const isApproved = reservationIsApproved({
              reservation
            })

            const from = toMeridian(_get(reservations, [a, 0], ''))
            const to = toMeridian(_get(reservations, [a, 1], ''))

            if (requiresApproval) {
              if (isApproved) {
                events.push({
                  name: [from, 'to', to].join(' '),
                  date
                })
              }
            }
          }
        }
      }
    }

    this.events = events
  },

  //
  //  Called after selecting either:
  //    All Approved Reservations
  //    All Reservations
  //
  async showReservations() {
    const amenityReservations = _get(this, ['amenityData', 'amenityReservations'], [])
    const requiresApproval = _get(this, ['amenityData', 'requiresApproval'], null)

    const { year, month } = this.$refs.calendar.getInfo()
    const { dates: datesInMonth } = allDatesInMonth({
      year,
      month
    })

    const { reservations: dates } = parseReservations({
      amenityReservations
    })

    const events = []
    const last = datesInMonth.length
    for (let a = 0; a < last; a++) {
      const date = datesInMonth[a]

      const _date = moment(date).format('YYYY-MM-DD')
      let reservations = dates[_date]
      if (Array.isArray(reservations) && reservations.length >= 1) {
        events.push({
          name: reservations
            .filter(reservation => {
              if (requiresApproval === false) {
                return true
              }

              const r = reservation[2]
              if (reservationIsApproved({ reservation: r })) {
                return true
              }

              // the reservation isn't approved
              return false
            })
            .map(reservation => {
              return `${toMeridian(reservation[0])} to ${toMeridian(reservation[1])}`
            })
            .join('\n'),
          date
        })
      }
    }

    this.events = events
  },
  async showReservableTimes() {
    const { reservableTimes: wkDays } = filterReservableTimes({
      amenityReservableTimes: this.amenityReservableTimes
    })
    const { year, month } = this.$refs.calendar.getInfo()
    const { dates: datesInMonth } = allDatesInMonth({
      year,
      month
    })

    const events = []
    const last = datesInMonth.length
    for (let a = 0; a < last; a++) {
      const date = datesInMonth[a]
      const m = moment(date)
      const dayOfWeek = m.day() + 1

      const reservableTimes = wkDays[wkDaysNums[dayOfWeek]]
      if (Array.isArray(reservableTimes) && reservableTimes.length >= 1) {
        events.push({
          name: reservableTimes
            .map(reservableTime => {
              return `${toMeridian(reservableTime.startTime)} to ${toMeridian(
                reservableTime.endTime
              )}`
            })
            .join('\n'),
          date
        })
      }
    }

    this.events = events
  },
  async showOpenings() {
    const amenityData = this.amenityData
    const { amenityReservations } = amenityData

    const dates = {
      /* 2021-11-04 */
    }

    const { reservableTimes: wkDays } = filterReservableTimes({
      amenityReservableTimes: this.amenityReservableTimes
    })
    this.reservableTimes = wkDays

    for (let a = 0; a < amenityReservations.length; a++) {
      const sDate = amenityReservations[a].startDate.split('T')
      const eDate = amenityReservations[a].endDate.split('T')

      if (timeIsInvalid(sDate[1])) {
        console.error('invalid date', { sDate })
        continue
      }
      if (timeIsInvalid(eDate[1])) {
        console.error('invalid date', { eDate })
        continue
      }

      if (!Array.isArray(dates[sDate[0]])) {
        dates[sDate[0]] = []
      }
      dates[sDate[0]].push([sDate[1], eDate[1]])
    }

    const { year, month } = this.$refs.calendar.getInfo()
    const { dates: datesInMonth } = allDatesInMonth({
      year,
      month
    })

    const events = []
    const last = datesInMonth.length
    for (let a = 0; a < last; a++) {
      const date = datesInMonth[a]

      const m = moment(date)
      // const y = m.year()
      // const mon = m.month() + 1
      // const dayOfMonth = m.date()
      const dayOfWeek = m.day() + 1

      const _date = moment(date).format('YYYY-MM-DD')
      let reservations = dates[_date]
      if (!Array.isArray(reservations)) {
        reservations = []
      }

      // availability for the particular day
      // of the week
      const availabilities = wkDays[wkDaysNums[dayOfWeek]]

      const { openings } = getTimeSpans({
        availabilities: availabilities.map(a => {
          return [a.startTime, a.endTime]
        }),
        reservations
      })

      if (Array.isArray(openings) && openings.length >= 1) {
        events.push({
          name: openings
            .map(opening => {
              return `${toMeridian(opening[0])} to ${toMeridian(opening[1])}`
            })
            .join('\n'),
          date
        })
      }
    }

    this.events = events
  },
  async showPendingReservationRequests({ ownerID } = {}) {
    const amenityReservations = _get(this, ['amenityData', 'amenityReservations'], [])

    const { year, month } = this.$refs.calendar.getInfo()
    const { dates: datesInMonth } = allDatesInMonth({
      year,
      month
    })

    const { reservations: dates } = parseReservations({
      amenityReservations,
      ...(typeof ownerID === 'number' && { ownerID })
    })

    const events = []
    const last = datesInMonth.length
    for (let a = 0; a < last; a++) {
      const date = datesInMonth[a]
      // const m = moment(date)
      // const dayOfWeek = m.day() + 1

      const _date = moment(date).format('YYYY-MM-DD')
      let reservations = dates[_date]
      if (Array.isArray(reservations) && reservations.length >= 1) {
        events.push({
          name: reservations
            .filter(reservation => {
              const r = reservation[2]

              if (reservationIsApproved({ reservation: r })) {
                return false
              }

              // the reservation isn't approved
              return true
            })
            .map(reservation => {
              return `${toMeridian(reservation[0])} to ${toMeridian(reservation[1])}`
            })
            .join('\n'),
          date
        })
      }
    }

    this.events = events
  },
  async show() {
    const showing = this.showing
    this.loading = true

    /*
			6 ->
			5 -> 
			4 ->
			3 ->
			2 ->
			1 ->
		*/
    if (showing === 6) {
      this.events = []
      await this.showPendingReservationRequests()
      this.loading = false
      return
    }
    if (showing === 5) {
      this.events = []
      await this.showReservations()
      this.loading = false
      return
    }
    if (showing === 4) {
      this.events = []
      await this.showPendingReservationRequests({
        ownerID: this.currentOwnerId
      })
      this.loading = false
      return
    }
    if (showing === 3) {
      this.events = []
      await this.showYourReservations()
      this.loading = false
      return
    }
    if (showing === 2) {
      this.events = []
      await this.showOpenings()
      this.loading = false
      return
    }
    if (showing === 1) {
      this.events = []
      await this.showReservableTimes()
      this.loading = false
      return
    }

    this.loading = false
    this.events = []
  }
  //|\_______________________________________________________________
  //|\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
}
