/*
	import { parseMoment } from '@/utilities/moment/parse'

	const parsed = parseMoment ({ m });
*/

/*
  relevant:
    moment ().add('7','days').utcOffset(0, true).toISOString ()
*/

import moment from 'moment'

export function parseMoment({ m: _m }) {
  try {
    let m
    if (typeof _m === 'string') {
      if (_m.slice(-1) === 'Z') {
        _m = _m.substring(0, _m.length - 1)
        m = moment(_m)
      } else {
        m = moment(_m)
      }
    } else {
      m = _m.utcOffset(0, true)
    }

    return {
      orbit: m.year(),
      //
      month: m.month() + 1,
      dayOfMonth: m.date(),
      //
      hour: m.hour(),
      minute: m.minute(),
      //
      time: m.format('HH:mm:ss'),
      m
    }
  } catch (x) {
    console.error(x)
  }

  return {
    orbit: null,
    month: null,
    dayOfMonth: null,
    hour: null,
    minute: null,
    time: null,
    m: null
  }
}
