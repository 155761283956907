/*
	import { toMeridian } from "@/utilities/time/toMeridian"

	const meridianTime = toMeridian ("00:00:00");

	plan:
		00:00:00 -> 12 am
		01:00:00 -> 1 am
		03:17:00 -> 3:17 am


		00 -> 12 am
		12 -> 12 pm
		13 -> 1 pm
		23 -> 11 pm
		24 -> 12 am

*/

import { timeIsInvalid } from '@/utilities/time/isInvalid'

export function toMeridian(t, { includeBreakdown = false } = {}) {
  if (timeIsInvalid(t)) {
    return t
  }

  const split = t.split(':')

  let result = {
    hour: '',
    minute: split[1],
    second: split[2],
    meridian: 'am'
  }

  const hour = parseInt(split[0])

  if ([0, 24].includes(hour)) {
    result.hour += '12'
    result.meridian = 'am'
  } else if (hour === 12) {
    result.hour += '12'
    result.meridian = 'pm'
  } else if (hour >= 13) {
    result.hour = hour - 12
    result.meridian = 'pm'
  } else if (hour >= 1 && hour <= 11) {
    result.hour = hour
    result.meridian = 'am'
  } else {
    console.error({ hour })
    throw new Error(t)
  }

  let final = result.hour

  if (split[1] !== '00') {
    final += ':' + split[1]
  }

  final += ' ' + result.meridian

  if (includeBreakdown) {
    if (typeof result.minute === 'number') {
      result.minute = result.minute.toString()
    }
    if (result.minute.length === 1) {
      result.minute = '0' + result.minute
    }

    if (typeof result.hour === 'number') {
      result.hour = result.hour.toString()
    }
    if (result.hour.length === 1) {
      result.hour = '0' + result.hour
    }

    return {
      final,
      result
    }
  }

  return final
}
