/*
	import { getDuration } from '@/utilities/time/getDuration'

	// in seconds
	const seconds = getDuration ([ "00:00:00", "05:00:00" ]);
*/

import { timeIsInvalid } from '@/utilities/time/isInvalid'

export function getDuration([t1, t2]) {
  if (timeIsInvalid(t1)) {
    return null
  }

  if (timeIsInvalid(t2)) {
    return null
  }

  const t1Parsed = t1.split(':')
  const t2Parsed = t2.split(':')

  const parsed = {
    t1: {
      h: parseInt(t1Parsed[0]),
      m: parseInt(t1Parsed[1]),
      s: parseInt(t1Parsed[2])
    },
    t2: {
      h: parseInt(t2Parsed[0]),
      m: parseInt(t2Parsed[1]),
      s: parseInt(t2Parsed[2])
    }
  }

  const inBetween =
    parsed.t2.h * 3600 +
    parsed.t2.m * 60 +
    parsed.t2.s -
    (parsed.t1.h * 3600 + parsed.t1.m * 60 + parsed.t1.s)

  return inBetween
}
