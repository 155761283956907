/*
	import { addSuffix } from '@/utilities/Date/addSuffix'
	addSuffix (date);
*/

/*
	1st
	2nd
	3rd
	
	if two digits & second to last digit is 1 then all "th"
*/

export function addSuffix(date) {
  try {
    const strDate = parseInt(date).toString()
    const len = strDate.length

    if (strDate.length === 1) {
      if (strDate === '1') {
        return strDate + 'st'
      }
      if (strDate === '2') {
        return strDate + 'nd'
      }
      if (strDate === '3') {
        return strDate + 'rd'
      }
    }

    if (len >= 2) {
      const secondToLast = strDate[len - 2]

      if (secondToLast !== '1') {
        const last = strDate[len - 1]

        if (last === '1') {
          return strDate + 'st'
        }
        if (last === '2') {
          return strDate + 'nd'
        }
        if (last === '3') {
          return strDate + 'rd'
        }
      }
    }

    return strDate + 'th'
  } catch (x) {
    console.error(x)
  }

  return date
}
