/*
	Both:
		Reservable Times
		Availability


	Approval Required:
		Home Owner:
			Your Reservations
			Your Reservation Requests

		Manager:
			All Reservations
			All Reservation Requests
		

	Approval Not Required:
		Home Owner:
			Your Reservations

		Manager:
			All Reservations
*/

import { mapState, mapGetters } from 'vuex'

export const computed = {
  ...mapGetters('user', ['authUser', 'currentHoaId', 'currentOwnerId']),
  ...mapState({
    breakpoint: state => state.win.breakpoint
  }),
  portal() {
    if (this.authUserType === 'HomeOwner') {
      return 1
    }
    if (this.authUserType === 'Account') {
      return 2
    }

    return null
  },
  homeOwnerDisplays() {
    //
    // these values are mapped to 'showing'
    // same as managementDisplays
    //

    const total = this.total
    const requiresApproval = this.requiresApproval

    const allReservationsText = requiresApproval ? 'All Approved Reservations' : 'All Reservations'

    const yourReservationsText = requiresApproval
      ? 'Your Approved Reservations'
      : 'Your Reservations'

    const options = [
      {
        text: 'Availability',
        value: 2
      },
      {
        text: `Reservable Times (${total.reservableTimes} total)`,
        value: 1
      },
      {
        text: [
          yourReservationsText,
          `(${total.yourFutureReservations} current/future)`,
          `(${total.yourPastReservations} past)`
        ].join(' '),
        value: 3
      },
      ...(requiresApproval === true
        ? [
            {
              text: [
                `Your Pending Reservation Requests`,
                `(${total.yourFutureReservationRequests} current/future)`,
                `(${total.yourPastReservationRequests} past)`
              ].join(' '),
              value: 4
            }
          ]
        : []),
      {
        text: [
          allReservationsText,
          `(${total.futureReservations} current/future)`,
          `(${total.pastReservations} past)`
        ].join(' '),
        value: 5
      },
      ...(requiresApproval === true
        ? [
            {
              text: [
                'All Pending Reservation Requests',
                `(${total.futureReservationRequests} current/future)`,
                `(${total.pastReservationRequests} past)`
              ].join(' '),
              value: 6
            }
          ]
        : [])
    ]

    return options
  },
  managementDisplays() {
    //
    // these values are mapped to 'showing'
    // same as homeOwnerDisplays
    //

    const total = this.total
    const requiresApproval = this.requiresApproval

    const allReservationsText = requiresApproval ? 'All Approved Reservations' : 'All Reservations'

    const options = [
      {
        text: 'Availability',
        value: 2
      },

      {
        text: `Reservable Times (pre-reservations) (${total.reservableTimes} total)`,
        value: 1
      },

      {
        text: [
          allReservationsText,
          `(${total.futureReservations} current/future)`,
          `(${total.pastReservations} past)`
        ].join(' '),
        value: 5
      },

      ...(requiresApproval === true
        ? [
            {
              text: [
                'All Pending Reservation Requests',
                `(${total.futureReservationRequests} current/future)`,
                `(${total.pastReservationRequests} past)`
              ].join(' '),
              value: 6
            }
          ]
        : [])
    ]

    return options
  }
}
