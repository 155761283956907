import { mapState, mapGetters } from 'vuex'

export const computed = {
  ...mapState({
    breakpoint: state => state.win.breakpoint,
    hoaId: state => state.user.selectedHoaId,
    ownerId: state => state.user.homeownerSelectedUnit.ownerId,
    ...mapGetters('user', ['authUser']),
    ...mapGetters('amenity', ['amenityID', 'reservationOwnerID', 'startDate', 'endDate'])
  })
}
