/*
  import { deleteMultipleReservations } from '@/services/Amenities/AmenityReservation/sequences/deleteMultiple';

  const { successes, problems } = await deleteMultipleReservations ({
    reservationIDs: [
      amenityReservationID,
      amenityReservationID,
      amenityReservationID
    ]
  });
  if (problems.length >= 1) {

  }
*/

import { deleteAmenityReservation } from '@/services/Amenities/AmenityReservation/Delete'

export async function deleteMultipleReservations({ reservationIDs }) {
  const results = await Promise.all(
    reservationIDs.map(amenityReservationID => {
      return deleteAmenityReservation({
        amenityReservationID
      })
    })
  )

  const problems = []
  const successes = []

  for (let a = 0; a < results.length; a++) {
    const result = results[a]
    const { successful } = result

    if (successful === true) {
      successes.push(result)
    } else {
      problems.push(result)
    }
  }

  return {
    successes,
    problems
  }
}
