import _get from 'lodash/get'

export function data() {
  const authUserType = _get(this, ['$store', 'getters', 'user/authUserType'], null)

  return {
    authUserType,
    showing: 2,
    reservationDurationLimitInMinutes: null,
    requiresApproval: null,
    name: '',
    loading: false,
    dayTappedDay: null,
    dayTappedMonth: null,
    dayTappedYear: null,
    attributes: [],
    events: [],
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    shown: {
      year: null,
      month: null
    },
    reservableTimes: [],

    /*
			management
				all reservations
				all reservation requests
				
				reservable times

			owner specific
				your future reservations
				your past reservations

				your future reservation requests
				your past reservation requests

			both
				availibility
	
		*/
    total: {
      // reservations: '__',
      // yourReservations: '__',
      reservableTimes: '__',
      //
      // allFutureReservationRequests: '__',
      // futurePendingReservations: '__',
      yourFutureReservations: '__',
      yourPastReservations: '__',
      yourFutureReservationRequests: '__',
      yourPastReservationRequests: '__',
      futureReservations: '__',
      pastReservations: '__',
      futureReservationRequests: '__',
      pastReservationRequests: '__'
    },
    //
    found: false
  }
}
