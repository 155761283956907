/*
	import { findReliantReservations } from '@/utilities/amenities/findReliantReservations'

	const { reliantReservations } = findReliantReservations ({
		// the reservable time that is slated for removal
		amenityReservableTimeID,

		reservableTimes: [],
		reservations: []
	})
*/

/*	
	spliced reservable times
	reservation times

	checking for reservations that would be invalidated
*/

import { timeIsAvailable } from '@/utilities/time/isAvailable'
import { wkDaysNums } from '@/utilities/Date/constants'

import _get from 'lodash/get'
import moment from 'moment'

export function findReliantReservations({
  amenityReservableTimeID,
  reservableTimes,
  reservations
}) {
  /*
    filters the reservable time with the ID specified
  */
  const blocks = {}
  reservableTimes.filter(r => {
    const id = _get(r, 'amenityReservableTimeID', '')
    if (typeof id === 'number' && id === amenityReservableTimeID) {
      return false
    }

    if (!Array.isArray(blocks[r.dayOfWeek])) {
      blocks[r.dayOfWeek] = []
    }
    blocks[r.dayOfWeek].push([r.startTime, r.endTime])

    return true
  })

  /*
		check for events that are on days that
		might no longer have a reservable time.

		For example:
			events: 
				Tuesday, 5am to 6am
				Tuesday, 5am to 11am
				Friday,  6am to 9am
				Friday,  4am to 7am

			blocks:
				Tuesday, 3am to 11am
				[Friday, 4am to 10pm] -> up for removal
	*/

  const reliantReservations = []
  for (let a = 0; a < reservations.length; a++) {
    const reservation = reservations[a]

    const reservationDayOfWeek = wkDaysNums[moment(_get(reservation, 'startDate', '')).day() + 1]
    const reservableTimesOnDay = blocks[reservationDayOfWeek]

    if (!Array.isArray(reservableTimesOnDay) || reservableTimesOnDay.length === 0) {
      reliantReservations.push(reservation)
      continue
    }

    const check = [
      _get(reservation, 'startDate', '').split('T')[1],
      _get(reservation, 'endDate', '').split('T')[1]
    ]
    const availabilities = reservableTimesOnDay

    const {
      available // boolean
    } = timeIsAvailable({
      check,
      availabilities,
      reservations: []
    })
    if (!available) {
      reliantReservations.push(reservation)
    }
  }

  return {
    reliantReservations
  }
}
