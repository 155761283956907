<template>
  <Modal amenities-reservability-adder :toggle.sync="isOpen" :loading="loading">
    <template v-slot:header>Amenity Reservable Times</template>

    <template v-slot:footer>
      <div :style="{ display: 'flex', justifyContent: 'end' }">
        <b-button type="is-primary" @click="add" add-button>Add</b-button>
      </div>
    </template>

    <b-field label="Every"></b-field>
    <b-select day-select placeholder="Day of Week" v-model="formData.dayOfWeek">
      <option v-for="(day, index) in weekDays" :value="day" :key="index">
        {{ day }}
      </option>
    </b-select>

    <br />

    <b-field label="From" />
    <TimePicker from-time ref="fromTime" />

    <br />

    <b-field label="To" />
    <TimePicker to-time ref="toTime" />

    <br />
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import TimePicker from '@/components/inputs/TimePickerFifteen'

import { methods } from './keys/methods'

export default {
  components: {
    Modal,
    TimePicker
  },
  props: {
    /*
      route path for testing purposes
    */
    _testAmenityID: {
      type: Number,
      default: undefined
    },
    refreshParent: Function
  },
  data() {
    return {
      isOpen: false,
      loading: true,
      callRefresh: false,
      formData: {
        dayOfWeek: '',
        cleanupDurationInMinutes: '0'
      },

      weekDays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    }
  },
  watch: {
    loading() {
      console.debug('loading...............................................')
    },
    callRefresh() {
      console.debug('watch callRefresh...' + this.callRefresh)
      if (this.callRefresh === true) {
        this.refreshParent()
      }
    }
  },
  methods
}
</script>
